import { ReactElement } from 'react';
import Stepper from '@/components/Stepper';
import IntroImage from '@/components/IntroImage';
import { translate } from '@/util/strings';
import Button from '@/components/Button';
import { redirectToContract } from '@/util/webApp';
import Icon from '@/components/Icon';
import { useReduxSelector } from '@/store/hooks';

function SellerWaitingForPayout(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);
  const buyerName: string = useReduxSelector(
    (state) => state.payment.buyerName
  );

  return (
    <div className="waiting-for-ais">
      <Stepper steps={4} active={4} complete={3} />
      <IntroImage src="/img/payment-receipt.svg" width={101} />
      <h1>{translate('seller.waitingForPayoutHead')}</h1>

      <p
        dangerouslySetInnerHTML={{
          __html: translate('seller.waitingForPayoutBody').replaceAll(
            '[firstName]',
            buyerName
          ),
        }}
      />

      <div className="buttons">
        <Button
          action={(): void => redirectToContract(contractId)}
          primary={false}
        >
          <Icon name="arrow-left" style="fas" />
          {translate('returnToContract')}
        </Button>
      </div>
    </div>
  );
}

export default SellerWaitingForPayout;
