import React, { ReactElement, useEffect } from 'react';
import { useReduxSelector } from '@/store/hooks';
import { useSearchParams } from 'react-router-dom';
import Loader, { LoaderStyles } from '@/components/Loader';
import './TinkRedirect.scss';

interface TinkRedirectProps {
  beforeRedirectText?: string;
  afterRedirectText?: string;
}

function TinkRedirect({
  beforeRedirectText = '',
  afterRedirectText = '',
}: TinkRedirectProps): ReactElement {
  const [searchParams] = useSearchParams();
  const tinkUrl = useReduxSelector((state) => state.payment.tinkUrl);
  const noRedirect = searchParams.get('no_redirect') || undefined;
  const tinkClientTypes = searchParams.get('tink_client_types') || undefined;

  useEffect(() => {
    if (tinkUrl && !noRedirect) {
      window.location.href = tinkUrl;
    }

    /**
     * @note Temporary check to fix issue when buyer is redirected from Tink AIS
     * with "no_redirect" param but state machine already has processed that
     * and payment is again in "BUYER_TINK_REDIRECT" UI state,
     * but this time waiting to be redirected to Tink PIS flow.
     * Redirect is blocked because "no_redirect" param prevents redirect,
     * and without this check, buyer is stuck with loader.
     */
    if (
      tinkUrl.includes('/pay/direct') &&
      tinkClientTypes === 'account-check'
    ) {
      window.location.href = tinkUrl;
    }
  }, [tinkUrl, noRedirect]);

  return (
    <div className="tink-redirect">
      <Loader color="#000" style={LoaderStyles.ROTATE_CIRCLE} />
      {!noRedirect && beforeRedirectText && (
        <h1 className="head">{beforeRedirectText}</h1>
      )}
      {noRedirect && afterRedirectText && (
        <h1 className="head">{afterRedirectText}</h1>
      )}
    </div>
  );
}

export default TinkRedirect;
