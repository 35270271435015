import React, { ReactElement } from 'react';
import classNames from 'classnames';
import './IntroCard.scss';

interface IntroCardProps {
  head?: string;
  body?: string;
  image?: string;
}

function IntroCard({
  head = '',
  body = '',
  image = '',
}: IntroCardProps): ReactElement {
  const className = classNames({
    'intro-card': true,
  });

  return (
    <div className={className}>
      <div className="image">{image && <img src={image} />}</div>
      <div className="text">
        {head && <h3 className="head">{head}</h3>}
        {body && <div className="body">{body}</div>}
      </div>
    </div>
  );
}

export default IntroCard;
