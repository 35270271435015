import { ReactElement } from 'react';
import Stepper from '@/components/Stepper';
import IntroImage from '@/components/IntroImage';
import Button from '@/components/Button';
import { translate } from '@/util/strings';
import { redirectToContract } from '@/util/webApp';
import { useReduxSelector } from '@/store/hooks';
import Icon from '@/components/Icon';
import './HandOver.scss';

function SellerWaitingForBuyerHandover(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);
  const buyerName: string = useReduxSelector(
    (state) => state.payment.buyerName
  );

  return (
    <div className="hand-over">
      <Stepper steps={4} active={4} complete={3} />
      <IntroImage src="/img/hour-glass.svg" width={56} />
      <h1>{translate('waitingForHandoverHead')}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('seller.waitingForHandoverBody').replaceAll(
            /\[firstName\]/g,
            buyerName
          ),
        }}
      />

      <div className="buttons">
        <Button
          primary={false}
          action={(): void => redirectToContract(contractId)}
        >
          <Icon name="arrow-left" style="fas" />
          {translate('returnToContract')}
        </Button>
      </div>
    </div>
  );
}

export default SellerWaitingForBuyerHandover;
