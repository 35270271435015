import { ReactElement, useState } from 'react';
import Button from '@/components/Button';
import IntroImage from '@/components/IntroImage';
import Stepper from '@/components/Stepper';
import { translate } from '@/util/strings';
import './SellerChooseBank.scss';
import BankSelector from '@/components/BankSelector';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { apiPut } from '@/util/api';
import { setError, bankChoiceDone } from '@/store/payment';
import { OuterPutBankRequest } from '@/models/api/outer/PutBankRequest';
import { OuterPutBankResponse } from '@/models/api/outer/PutBankResponse';
import { models } from '@swiftcourt/pay-spec';
import Select from '@/components/Select';
import './BuyerChooseBank.scss';
import TipsBox from '@/components/TipsBox';
import { ErrorCodes } from '@/models/ErrorCodes';

const moneyOriginOptions: Record<string, string> =
  models.v2.bankInformation.moneyOrigin;

function BuyerChooseBank(): ReactElement {
  const paymentId = useReduxSelector((state) => state.payment.paymentId);
  const choiceDone = useReduxSelector(bankChoiceDone);
  const bankBranchChoice = useReduxSelector(
    (state) => state.payment.bankBranchChoice
  );
  const [isLoading, setIsLoading] = useState(false);
  const [moneyOrigin, setMoneyOrigin] = useState(
    models.v2.bankInformation.moneyOrigin.NOT_SET
  );
  const dispatch = useReduxDispatch();

  const submitBankChoice = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<OuterPutBankRequest, OuterPutBankResponse>(
      `payments/${paymentId}/bank`,
      { bankChoice: bankBranchChoice, moneyOrigin }
    );
    if (!apiRes.isOk()) {
      dispatch(setError(ErrorCodes.BANK_CHOICE_REQUEST));
    }
  };

  const handleChangeMoneyOrigin = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setMoneyOrigin(event.target.value as models.v2.bankInformation.moneyOrigin);
  };

  return (
    <div className="buyer-choose-bank">
      <Stepper steps={4} active={3} complete={2} />
      <IntroImage src="/img/phone-with-piggy-bank.svg" width={136} />
      <h1>{translate('buyer.chooseBankHead')}</h1>
      <div
        dangerouslySetInnerHTML={{ __html: translate('buyer.chooseBankBody') }}
      />

      <Select onChange={handleChangeMoneyOrigin}>
        <option value=""></option>
        {Object.keys(moneyOriginOptions)
          .filter((originKey) => originKey !== 'NOT_SET')
          .map((originKey) => (
            <option key={originKey} value={originKey}>
              {translate(`buyer.moneyOrigin.${originKey}`)}
            </option>
          ))}
      </Select>

      <div className="bank-select-wrapper">
        <h4>{translate('buyer.bankListHead')}</h4>

        <BankSelector />
      </div>

      <TipsBox
        head={translate('buyer.chooseBankTipsHead')}
        tips={[translate('buyer.chooseBankTipsBody')]}
      />

      <div className="buttons">
        <Button
          text={translate('buyer.submit')}
          action={submitBankChoice}
          loading={isLoading}
          disabled={
            !choiceDone ||
            moneyOrigin === models.v2.bankInformation.moneyOrigin.NOT_SET
          }
        />
      </div>
    </div>
  );
}

export default BuyerChooseBank;
