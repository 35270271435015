import React, { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import './IntroImage.scss';
import { useReduxSelector } from '@/store/hooks';

interface IntroImageProps {
  src: string;
  width?: number | string;
  height?: number | string;
}

function IntroImage({
  src = '',
  width = 'auto',
  height = 'auto',
}: IntroImageProps): ReactElement {
  const [verticalSrc, setVerticalSrc] = useState('');
  const vertical = useReduxSelector((state) => state.payment.vertical);

  const className = classNames({
    'intro-image': true,
  });

  useEffect(() => {
    setVerticalSrc(['', vertical, src.replace(/^\/+/, '')].join('/'));
  }, [src, vertical]);

  return (
    <div className={className}>
      <img src={verticalSrc} width={width} height={height} />
    </div>
  );
}

export default IntroImage;
