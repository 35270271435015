import { ReactElement } from 'react';
import Stepper from '@/components/Stepper';
import IntroImage from '@/components/IntroImage';
import Button from '@/components/Button';
import { translate } from '@/util/strings';
import './BuyerHandoverConfirmed.scss';
import { useReduxSelector } from '@/store/hooks';
import Icon from '@/components/Icon';
import { redirectToContract } from '@/util/webApp';

function BuyerHandoverConfirmed(): ReactElement {
  const sellerName: string = useReduxSelector(
    (state) => state.payment.sellerName
  );
  const contractId = useReduxSelector((state) => state.payment.contractId);

  return (
    <div className="handover-confirmed">
      <Stepper steps={4} active={4} complete={3} />
      <IntroImage src="/img/payment-receipt.svg" width={101} />
      <h1>{translate('buyer.handoverConfirmedHead')}</h1>

      <div
        dangerouslySetInnerHTML={{
          __html: translate('buyer.handoverConfirmedBody').replaceAll(
            '[firstName]',
            sellerName
          ),
        }}
      />

      <div className="buttons">
        <Button
          action={(): void => redirectToContract(contractId)}
          primary={false}
        >
          <Icon name="arrow-left" style="fas" />
          {translate('returnToContract')}
        </Button>
      </div>
    </div>
  );
}

export default BuyerHandoverConfirmed;
