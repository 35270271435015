import { ReactElement, useState } from 'react';
import IntroCard from '@/components/IntroCard';
import Button from '@/components/Button';
import TermsCheckBox from '@/components/TermsCheckbox';
import { translate } from '@/util/strings';
import { BuyerAcceptTermsRequest } from '@/models/api/buyer/AcceptTermsRequest';
import { BuyerAcceptTermsResponse } from '@/models/api/buyer/AcceptTermsResponse';
import { redirectToContract } from '@/util/webApp';
import { apiPut } from '@/util/api';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { showLoader } from '@/store/payment';
import { setError } from '@/store/payment';
import './BuyerHowItWorks.scss';
import TipsBox from '@/components/TipsBox';
import Icon from '@/components/Icon';
import { ErrorCodes } from '@/models/ErrorCodes';

export interface ViewProps {
  sellerIsDone?: boolean;
}

function BuyerHowItWorks({ sellerIsDone = false }: ViewProps): ReactElement {
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const paymentId: string = useReduxSelector(
    (state) => state.payment.paymentId
  );
  const contractId = useReduxSelector((state) => state.payment.contractId);
  const vertical = useReduxSelector((state) => state.payment.vertical);
  const dispatch = useReduxDispatch();

  const acceptTermsHandler = (): void => {
    setTermsAgreed(!termsAgreed);
  };

  const acceptTermsAndConditions = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<
      BuyerAcceptTermsRequest,
      BuyerAcceptTermsResponse
    >(`/payments/${paymentId}/terms`, {});

    if (apiRes.isErr()) {
      dispatch(setError(ErrorCodes.ACCEPT_TERMS_REQUEST));
    }

    dispatch(showLoader());
  };
  return (
    <div className="how-it-works">
      <h1>{translate('introHead')}</h1>

      {[1, 2, 3, 4].map((step) => (
        <IntroCard
          key={step}
          image={`/${vertical}/img/buyer-how-it-works-${step}.svg`}
          head={translate(`buyer.step${step}Head`)}
          body={translate(`buyer.step${step}Body`)}
        />
      ))}

      {sellerIsDone && (
        <>
          <TermsCheckBox
            content={translate('acceptTerms')}
            value={termsAgreed}
            onChange={acceptTermsHandler}
          />
          <div className="button-container">
            <Button
              disabled={!termsAgreed}
              action={acceptTermsAndConditions}
              loading={isLoading}
            >
              {translate('getStarted')}
              <Icon name="arrow-right" style="fas" />
            </Button>
          </div>
        </>
      )}
      {!sellerIsDone && (
        <div className="buyer-confirm">
          <TipsBox
            head={translate('buyer.whatIsNextHead')}
            tips={[translate('buyer.whatIsNextBody')]}
          />
          <div className="buttons">
            <Button
              action={(): void => redirectToContract(contractId)}
              primary={false}
            >
              <Icon name="arrow-left" style="fas" />
              {translate('returnToContract')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BuyerHowItWorks;
