import { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';

import Error from '@/views/Error';
import LongLoading from '@/views/LongLoading';
import GetLoginToken from '@/views/GetLoginToken';
import NotFound from '@/views/NotFound';
import UiState from '@/views/UiState';

function PageRouter(): ReactElement {
  return (
    <Routes>
      <Route path="/payment/:paymentId" element={<UiState />} />
      <Route path="/payments/:paymentId" element={<UiState />} />
      <Route path="/login" element={<GetLoginToken />} />
      <Route path="/loading" element={<LongLoading />} />
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PageRouter;
