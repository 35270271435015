import classNames from 'classnames';
import { ReactElement } from 'react';
import './TipsBox.scss';

declare interface TipsBoxProps {
  head?: string;
  tips: string[];
}

function TipsBox({ head, tips }: TipsBoxProps): ReactElement {
  /**
   * Parses a tip string with <li> tags and extracts the inner content.
   *
   * @param {string} tip - The tip string to be parsed.
   * @returns {string[]} The array of parsed tip items.
   */
  const parseTipsWithTag = (tip: string): string[] => {
    const liElRegex = /<li>(.*?)<\/li>/g;
    const regexMatches = tip.match(liElRegex);
    return regexMatches
      ? regexMatches.map((m) => m.replace(/<\/?li>/g, ''))
      : [tip];
  };

  /**
   * Sanitizes the HTML content by removing potentially harmful tags.
   *
   * @param {string} content - The HTML content to be sanitized.
   * @returns {string} The sanitized HTML content.
   */
  const sanitizeHTML = (content: string): string => {
    const harmfulTagsRegex =
      /<\/?(script|alert|style|iframe|object|embed|applet|meta|form|input|textarea|select|button|img)[^>]*>/gi;
    const sanitizedContent = content.replace(harmfulTagsRegex, '');
    return sanitizedContent;
  };

  /**
   * Array of parsed tips with key-value pairs.
   *
   * @type {Array<{ key: string, tip: string }>}
   */
  const parsedTips = tips.flatMap((tip, index) =>
    parseTipsWithTag(tip).map((parsedTip) => ({
      key: `tip-${index++}`,
      tip: sanitizeHTML(parsedTip),
    }))
  );

  return (
    <div
      className={classNames({
        tips: true,
        '--single': parsedTips.length === 1,
      })}
    >
      {head && <h4>{head}</h4>}
      <ul>
        {parsedTips.map(({ key, tip }) => (
          <li key={key} dangerouslySetInnerHTML={{ __html: tip }} />
        ))}
      </ul>
    </div>
  );
}

export default TipsBox;
