export enum ErrorCodes {
  INVALID_ORIGIN = 'FE0001',
  MISSING_OTT = 'FE0002',
  MISSING_REDIRECT = 'FE0003',
  OTT_EXCHANGE_REQUEST = 'FE0004',
  ACCEPT_TERMS_REQUEST = 'FE0005',
  BANK_CHOICE_REQUEST = 'FE0006',
  BANK_SESSION_RESET_REQUEST = 'FE0007',
  CONFIRM_PRODUCT_REQUEST = 'FE0008',
  GET_PAYMENT_REQUEST = 'FE0009',
  BUYER_NAME_MISMATCH_REASON_REQUEST = 'FE0010',
  BUYER_TRANSFER_RESET_REQUEST = 'FE0011',
  BUYER_CONFIRM_PAYMENT_REQUEST = 'FE0012',
  BANK_CHOICE_V2_REQUEST = 'FE0013',
  CONFIRM_REMAINING_REQUEST = 'FE0014',
  CHOOSE_PAYMENT_METHOD_REQUEST = 'FE0015',
  ABORT_PAYMENT_METHOD_REQUEST = 'FE0016',
  CONFIRM_IBAN_PAYMENT_REQUEST = 'FE0017',
  ABORT_IBAN_PAYMENT_REQUEST = 'FE0018',
}
