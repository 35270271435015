import React, { ReactElement, useEffect, useState } from 'react';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';

import {
  PaymentRole,
  setPaymentId,
  setError,
  hideLoader,
  setPayment,
} from '@/store/payment';
import {
  CONNECTION_REFUSED_MESSAGE,
  NOT_FOUND_MESSAGE,
  RESPONSE_NOT_OK_MESSAGE,
  apiGet,
} from '@/util/api';
import { redirectToLogin } from '@/util/webApp';

// Seller
import SellerBankSessionReset from '@/views/SellerBankSessionReset';
import SellerChooseBank from '@/views/SellerChooseBank';
import SellerConfirmProduct from '@/views/SellerConfirmProduct';
import SellerConnectingToKlarna from '@/views/SellerConnectingToKlarna';
import SellerCreatingEscrow from '@/views/SellerCreatingEscrow';
import SellerHandoverConfirmed from '@/views/SellerHandoverConfirmed';
import SellerHowItWorks from '@/views/SellerHowItWorks';
import SellerKlarnaPopup from '@/views/SellerKlarnaPopup';
import SellerKycProcessing from '@/views/SellerKycProcessing';
import SellerPayoutCompleted from '@/views/SellerPayoutCompleted';
import SellerWaitingForBuyer from '@/views/SellerWaitingForBuyer';
import SellerWaitingForBuyerHandover from '@/views/SellerWaitingForBuyerHandover';
import SellerWaitingForPayIn from '@/views/SellerWaitingForPayIn';
import SellerWaitingForPayout from '@/views/SellerWaitingForPayout';
import SellerWaitingForSellerHandover from '@/views/SellerWaitingForSellerHandover';

// Buyer
import BuyerBankSessionReset from '@/views/BuyerBankSessionReset';
import BuyerChooseBank from '@/views/BuyerChooseBank';
import BuyerConfirmPurchase from '@/views/BuyerConfirmPurchase';
import BuyerConnectingToKlarna from '@/views/BuyerConnectingToKlarna';
import BuyerHandoverConfirmed from '@/views/BuyerHandoverConfirmed';
import BuyerHowItWorks from '@/views/BuyerHowItWorks';
import BuyerKlarnaPopup from '@/views/BuyerKlarnaPopup';
import BuyerNameMismatch from '@/views/BuyerNameMismatch';
import BuyerPayoutCompleted from '@/views/BuyerPayoutCompleted';
import BuyerTinkError from '@/views/Error';
import BuyerWaitingForBuyerHandover from '@/views/BuyerWaitingForBuyerHandover';
import BuyerWaitingForPayIn from '@/views/BuyerWaitingForPayIn';
import BuyerWaitingForSellerHandover from '@/views/BuyerWaitingForSellerHandover';
import BuyerWaitingForNameMismatchApproval from '@/views/BuyerWaitingForNameMismatchApproval';
import BuyerUnderfunded from '@/views/BuyerUnderfunded';

import Loading from '@/views/Loading';
import LongLoading from '@/views/LongLoading';
import NotFound from '@/views/NotFound';

import { ui as uiSpec, models } from '@swiftcourt/pay-spec';
import { useParams, useSearchParams } from 'react-router-dom';
import { ErrorCodes } from '@/models/ErrorCodes';
import SellerTinkRedirect from '@/views/SellerTinkRedirect';
import BuyerTinkRedirect from '@/views/BuyerTinkRedirect';
import PaymentAborted from '@/views/PaymentAborted';
import BuyerTransferReset from '@/views/BuyerTransferReset';
import BuyerConfirmPayment from '@/views/BuyerConfirmPayment';
import BuyerChooseBankV2 from '@/views/BuyerChooseBankV2';
import BuyerChoosePaymentMethod from '@/views/BuyerChoosePaymentMethod';
import BuyerIbanPayment from '@/views/BuyerIbanPayment';

const POLL_FREQUENCY: number = parseInt(
  process.env.REACT_APP_POLL_FREQUENCY || '1000'
);

const MIN_FAILED_REQUESTS: number = parseInt(
  process.env.REACT_APP_MIN_FAILED_REQUESTS || '5'
);

function UiState(): ReactElement {
  const defaultView = <></>;
  const role: PaymentRole = useReduxSelector((state) => state.payment.role);
  const params = useParams();
  const uiState = useReduxSelector((state) => state.payment.uiState);
  const error = useReduxSelector((state) => state.payment.error);
  const showLoader = useReduxSelector((state) => state.payment.showLoader);
  const clientToken = useReduxSelector((state) => state.payment.clientToken);
  const [activeView, setActiveView] = useState(defaultView);
  const [failedRequestCount, setFailedRequestCount] = useState<number>(0);
  const dispatch = useReduxDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const hasReceivedResponse = useReduxSelector(
    (state) => state.payment.hasReceivedResponse
  );

  const paymentId = params.paymentId || '';

  const viewMap: Map<uiSpec.UiState, ReactElement> = new Map([
    [uiSpec.UiState.SPINNER, <LongLoading />],
    [uiSpec.UiState.SELLER_NOT_STARTED, <Loading />],
    [uiSpec.UiState.SELLER_BANK_SESSION_RESET, <SellerBankSessionReset />],
    [uiSpec.UiState.SELLER_CHOOSE_BANK, <SellerChooseBank />],
    [uiSpec.UiState.SELLER_CONFIRM_PRODUCT, <SellerConfirmProduct />],
    [uiSpec.UiState.SELLER_CONNECTING_TO_KLARNA, <SellerConnectingToKlarna />],
    [uiSpec.UiState.SELLER_CREATING_ESCROW, <SellerCreatingEscrow />],
    [uiSpec.UiState.SELLER_WAITING_FOR_PAY_IN, <SellerWaitingForPayIn />],
    [
      uiSpec.UiState.SELLER_WAITING_FOR_BUYER_HANDOVER,
      <SellerWaitingForBuyerHandover />,
    ],
    [
      uiSpec.UiState.SELLER_WAITING_FOR_SELLER_HANDOVER,
      <SellerWaitingForSellerHandover />,
    ],
    [uiSpec.UiState.SELLER_HANDOVER_CONFIRMED, <SellerHandoverConfirmed />],
    [uiSpec.UiState.SELLER_HOW_IT_WORKS, <SellerHowItWorks />],
    [uiSpec.UiState.SELLER_KLARNA_POPUP, <SellerKlarnaPopup />],
    [uiSpec.UiState.SELLER_TINK_REDIRECT, <SellerTinkRedirect />],
    [uiSpec.UiState.SELLER_PAYOUT_COMPLETED, <SellerPayoutCompleted />],
    [uiSpec.UiState.SELLER_WAITING_FOR_BUYER_STEPS, <SellerWaitingForBuyer />],
    [uiSpec.UiState.SELLER_WAITING_FOR_PAYOUT, <SellerWaitingForPayout />],
    [uiSpec.UiState.SELLER_KYC_PROCESSING, <SellerKycProcessing />],
    [uiSpec.UiState.BUYER_NOT_STARTED, <Loading />],
    [uiSpec.UiState.BUYER_BANK_SESSION_RESET, <BuyerBankSessionReset />],
    [uiSpec.UiState.BUYER_CHOOSE_BANK, <BuyerChooseBank />],
    [uiSpec.UiState.BUYER_CHOOSE_BANK_V2, <BuyerChooseBankV2 />],
    [uiSpec.UiState.BUYER_CONNECTING_TO_KLARNA, <BuyerConnectingToKlarna />],
    [uiSpec.UiState.BUYER_CONFIRM_PURCHASE, <BuyerConfirmPurchase />],
    [uiSpec.UiState.BUYER_CONFIRM_PAYMENT, <BuyerConfirmPayment />],
    [uiSpec.UiState.BUYER_CHOOSE_PAYMENT_METHOD, <BuyerChoosePaymentMethod />],
    [uiSpec.UiState.BUYER_IBAN_PAYMENT, <BuyerIbanPayment />],
    [uiSpec.UiState.BUYER_WAITING_FOR_PAY_IN, <BuyerWaitingForPayIn />],
    [
      uiSpec.UiState.BUYER_WAITING_FOR_BUYER_HANDOVER,
      <BuyerWaitingForBuyerHandover />,
    ],
    [
      uiSpec.UiState.BUYER_WAITING_FOR_SELLER_HANDOVER,
      <BuyerWaitingForSellerHandover />,
    ],
    [uiSpec.UiState.BUYER_HANDOVER_CONFIRMED, <BuyerHandoverConfirmed />],
    [
      uiSpec.UiState.BUYER_HOW_IT_WORKS,
      <BuyerHowItWorks sellerIsDone={true} />,
    ],
    [
      uiSpec.UiState.BUYER_KLARNA_POPUP,
      <BuyerKlarnaPopup key={clientToken.slice(-10)} />,
    ],
    [uiSpec.UiState.BUYER_TINK_REDIRECT, <BuyerTinkRedirect />],
    [uiSpec.UiState.BUYER_TINK_ERROR, <BuyerTinkError />],
    [uiSpec.UiState.BUYER_PAYOUT_COMPLETED, <BuyerPayoutCompleted />],
    [
      uiSpec.UiState.BUYER_WAITING_FOR_SELLER_STEPS,
      <BuyerHowItWorks sellerIsDone={false} />,
    ],
    [
      uiSpec.UiState.BUYER_WAITING_FOR_NAME_MISMATCH_APPROVAL,
      <BuyerWaitingForNameMismatchApproval />,
    ],
    [uiSpec.UiState.BUYER_NAME_MISMATCH, <BuyerNameMismatch />],
    [uiSpec.UiState.BUYER_TRANSFER_RESET, <BuyerTransferReset />],
    [uiSpec.UiState.BUYER_UNDERFUNDED, <BuyerUnderfunded />],
    // Aborted UI states, same page for both seller and buyer
    [uiSpec.UiState.SELLER_ABORTED, <PaymentAborted />],
    [uiSpec.UiState.BUYER_ABORTED, <PaymentAborted />],
  ]);

  const viewsWithPolling = [
    uiSpec.UiState.SPINNER,
    uiSpec.UiState.SELLER_BANK_SESSION_RESET,
    uiSpec.UiState.SELLER_CHOOSE_BANK,
    uiSpec.UiState.SELLER_CONFIRM_PRODUCT,
    uiSpec.UiState.SELLER_NOT_STARTED,
    uiSpec.UiState.SELLER_HANDOVER_CONFIRMED,
    uiSpec.UiState.SELLER_HOW_IT_WORKS,
    uiSpec.UiState.SELLER_CONNECTING_TO_KLARNA,
    uiSpec.UiState.SELLER_CREATING_ESCROW,
    uiSpec.UiState.SELLER_KLARNA_POPUP,
    uiSpec.UiState.SELLER_TINK_REDIRECT,
    uiSpec.UiState.SELLER_WAITING_FOR_BUYER_HANDOVER,
    uiSpec.UiState.SELLER_WAITING_FOR_BUYER_STEPS,
    uiSpec.UiState.SELLER_WAITING_FOR_SELLER_HANDOVER,
    uiSpec.UiState.SELLER_WAITING_FOR_PAY_IN,
    uiSpec.UiState.SELLER_WAITING_FOR_PAYOUT,
    uiSpec.UiState.SELLER_PAYOUT_COMPLETED,
    uiSpec.UiState.SELLER_KYC_PROCESSING,
    uiSpec.UiState.BUYER_BANK_SESSION_RESET,
    uiSpec.UiState.BUYER_CHOOSE_BANK,
    uiSpec.UiState.BUYER_CHOOSE_BANK_V2,
    uiSpec.UiState.BUYER_CONFIRM_PURCHASE,
    uiSpec.UiState.BUYER_CONFIRM_PAYMENT,
    uiSpec.UiState.BUYER_CHOOSE_PAYMENT_METHOD,
    uiSpec.UiState.BUYER_IBAN_PAYMENT,
    uiSpec.UiState.BUYER_KLARNA_POPUP,
    uiSpec.UiState.BUYER_TINK_REDIRECT,
    uiSpec.UiState.BUYER_NOT_STARTED,
    uiSpec.UiState.BUYER_HANDOVER_CONFIRMED,
    uiSpec.UiState.BUYER_HOW_IT_WORKS,
    uiSpec.UiState.BUYER_CONNECTING_TO_KLARNA,
    uiSpec.UiState.BUYER_WAITING_FOR_BUYER_HANDOVER,
    uiSpec.UiState.BUYER_WAITING_FOR_PAY_IN,
    uiSpec.UiState.BUYER_PAYOUT_COMPLETED,
    uiSpec.UiState.BUYER_WAITING_FOR_SELLER_HANDOVER,
    uiSpec.UiState.BUYER_WAITING_FOR_SELLER_STEPS,
    uiSpec.UiState.BUYER_TINK_ERROR,
    uiSpec.UiState.BUYER_WAITING_FOR_NAME_MISMATCH_APPROVAL,
    uiSpec.UiState.BUYER_NAME_MISMATCH,
    uiSpec.UiState.BUYER_TRANSFER_RESET,
    uiSpec.UiState.BUYER_UNDERFUNDED,
  ];

  const getFlow = async (): Promise<void> => {
    const apiRes = await apiGet<models.v2.getPayment.GetPayment>(
      `v2/payments/${paymentId}`
    );
    if (apiRes.isOk()) {
      if (apiRes.value) {
        if (apiRes.value.vertical) {
          sessionStorage.setItem('vertical', apiRes.value.vertical);
        }
        if (apiRes.value.language) {
          sessionStorage.setItem('language', apiRes.value.language);
        }
        dispatch(setPayment(apiRes.value));
        setFailedRequestCount(0);
      }
      return;
    }

    if (apiRes.error.message === NOT_FOUND_MESSAGE) {
      setFailedRequestCount(MIN_FAILED_REQUESTS);
      setActiveView(<NotFound />);
    }

    if (
      apiRes.error.message === CONNECTION_REFUSED_MESSAGE ||
      apiRes.error.message === RESPONSE_NOT_OK_MESSAGE
    ) {
      const newFailedRequestCount = failedRequestCount + 1;
      setFailedRequestCount(newFailedRequestCount);

      if (newFailedRequestCount >= MIN_FAILED_REQUESTS) {
        dispatch(setError(ErrorCodes.GET_PAYMENT_REQUEST));
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(async () => {
      if (
        viewsWithPolling.indexOf(uiState) > -1 &&
        paymentId &&
        !error &&
        failedRequestCount < MIN_FAILED_REQUESTS
      ) {
        await getFlow();
      }
    }, POLL_FREQUENCY);

    return () => clearInterval(timer);
  }, [paymentId, clientToken, uiState, failedRequestCount]);

  useEffect(() => {
    let view = defaultView;
    if (paymentId) {
      if (viewMap.has(uiState)) {
        view = viewMap.get(uiState) || <></>;
      }
    }
    setActiveView(view);
  }, [role, uiState, paymentId]);

  useEffect(() => {
    dispatch(hideLoader());
  }, [activeView, clientToken]);

  useEffect(() => {
    dispatch(setPaymentId(paymentId));
    if (!sessionStorage.getItem('loginToken')) {
      redirectToLogin();
    }
  }, []);

  /**
   * When navigating away from UI state "SELLER_TINK_REDIRECT" or "BUYER_TINK_REDIRECT"
   * this hook make sure that "no_redirect" param is removed from query params.
   */
  useEffect(() => {
    const redirectStates = [
      uiSpec.UiState.BUYER_TINK_REDIRECT,
      uiSpec.UiState.SELLER_TINK_REDIRECT,
    ];

    if (hasReceivedResponse && !redirectStates.includes(uiState)) {
      searchParams.delete('no_redirect');
      searchParams.delete('tink_client_types');
      setSearchParams(searchParams);
    }
  }, [uiState, hasReceivedResponse]);

  return (
    <React.Fragment>{showLoader ? <Loading /> : activeView}</React.Fragment>
  );
}

export default UiState;
