import { ReactElement, useEffect, useState } from 'react';
import Stepper from '@/components/Stepper';
import IntroImage from '@/components/IntroImage';
import Button from '@/components/Button';
import { translate } from '@/util/strings';
import './BuyerUnderfunded.scss';
import { setError, showLoader } from '@/store/payment';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { apiPut } from '@/util/api';
import { ErrorCodes } from '@/models/ErrorCodes';
import { getCurrencySymbol } from '@/util/currency';
import {
  ConfirmRemainingRequest,
  ConfirmRemainingResponse,
} from '@/models/api/buyer/ConfirmRemaining';
import { models } from '@swiftcourt/pay-spec';

const totalAmountKey =
  models.v2.getPayment.TransactionListItemName.TOTAL_AMOUNT;
const remainingAmountKey =
  models.v2.getPayment.TransactionListItemName.REMAINING_AMOUNT;

function BuyerUnderfunded(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const paymentId: string = useReduxSelector(
    (state) => state.payment.paymentId
  );
  const dispatch = useReduxDispatch();

  const transactionList = useReduxSelector(
    (state) => state.payment.transactionList
  );
  const currency = useReduxSelector((state) => state.payment.currency);

  // Set totalAmount and remainingAmount values from transaction list
  useEffect(() => {
    const totalAmountItem = transactionList.find(
      (i) => i.name === totalAmountKey
    );
    if (totalAmountItem !== undefined) {
      setTotalAmount(totalAmountItem.value);
    }

    const remainingAmountItem = transactionList.find(
      (i) => i.name === remainingAmountKey
    );
    if (remainingAmountItem !== undefined) {
      setRemainingAmount(remainingAmountItem.value);
    }
  }, [transactionList]);

  const confirmRemaining = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<
      ConfirmRemainingRequest,
      ConfirmRemainingResponse
    >(`/payments/${paymentId}/remaining-amount-confirmation`, {});

    if (apiRes.isErr()) {
      dispatch(setError(ErrorCodes.CONFIRM_REMAINING_REQUEST));
    }

    dispatch(showLoader());
  };

  return (
    <div className="underfunded">
      <Stepper steps={4} active={3} complete={2} />
      <IntroImage src="/img/payment-cancelled.svg" width={136} />
      <h1>{translate('paymentUnderfundedHead')}</h1>

      <div
        dangerouslySetInnerHTML={{
          __html: translate('paymentUnderfundedBody'),
        }}
      />

      <div className="transactions-summary">
        <table>
          <tbody>
            <tr className="summary-row">
              <td>{translate('buyer.confirmAmountTableRow1')}</td>
              <td>
                {getCurrencySymbol(currency)} {totalAmount.toLocaleString()}
              </td>
            </tr>
            <tr className="summary-row">
              <td>{translate('buyer.confirmAmountTableRow2')}</td>
              <td>
                {getCurrencySymbol(currency)}{' '}
                {(totalAmount - remainingAmount).toLocaleString()}
              </td>
            </tr>
            <tr className="summary-row">
              <td>{translate('buyer.confirmAmountTableRow3')}</td>
              <td>
                {getCurrencySymbol(currency)} {remainingAmount.toLocaleString()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="buttons">
        <Button
          text={translate('pay')}
          action={confirmRemaining}
          loading={isLoading}
        />
      </div>
    </div>
  );
}

export default BuyerUnderfunded;
