import React, { ReactElement, useState } from 'react';
import './DownloadReceiptBox.scss';
import Button from './Button';
import Icon from './Icon';
import { translate } from '@/util/strings';
import { useReduxSelector } from '@/store/hooks';
import { apiGet } from '@/util/api';
import { models } from '@swiftcourt/pay-spec';
import { CONTENT_TYPE } from '@/util/api';

interface DownloadReceiptBoxProps {
  type: models.v2.receipt.ReceiptType;
}

function DownloadReceiptBox({
  type = models.v2.receipt.ReceiptType.TRANSACTION,
}: DownloadReceiptBoxProps): ReactElement {
  const paymentId: string = useReduxSelector(
    (state) => state.payment.paymentId
  );
  const vertical: string = useReduxSelector((state) => state.payment.vertical);
  const receipt: models.v2.receipt.UserReceipt = useReduxSelector(
    (state) => state.payment.receipt
  );

  const downloadReady = receipt[type]?.ready; // Enable download button only if receipt is ready for download

  const [showError, setShowError] = useState(false);
  const [downloadOngoing, setDownloadOngoing] = useState(false);

  const receiptName =
    type === models.v2.receipt.ReceiptType.SERVICE
      ? translate('serviceReceipt')
      : translate('transactionReceipt');

  const downloadReceipt = async (): Promise<void> => {
    setDownloadOngoing(true);

    const headers = new Headers();
    headers.set('content-type', CONTENT_TYPE.PDF);

    const apiRes = await apiGet<string>(
      `payments/${paymentId}/${type}-receipt`,
      {
        headers,
      }
    );

    if (apiRes.isOk()) {
      const url = window.URL.createObjectURL(new Blob([apiRes.value]));
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `${receiptName}.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setShowError(false);
    } else {
      setShowError(true);
    }

    setDownloadOngoing(false);
  };

  return (
    <div className="download-box">
      <div className="image">
        <img src={`/${vertical}/img/document-icon.svg`} />
      </div>
      {receiptName}
      <Button
        action={async (): Promise<void> => await downloadReceipt()}
        loading={!downloadReady || downloadOngoing}
        disabled={!downloadReady || downloadOngoing}
        primary={true}
      >
        <Icon name="download" />
        {translate('downloadButtonText')}
      </Button>
      {showError && (
        <div
          className="error-details"
          dangerouslySetInnerHTML={{
            __html: translate('error'),
          }}
        ></div>
      )}
    </div>
  );
}

export default DownloadReceiptBox;
