import React, { ReactElement, useState } from 'react';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { setError, showLoader } from '@/store/payment';
import { apiPut } from '@/util/api';
import { translate } from '@/util/strings';
import Button from '@/components/Button';
import IntroImage from '@/components/IntroImage';
import { ErrorCodes } from '@/models/ErrorCodes';
import { BuyerTransferResetRequest } from '@/models/api/buyer/BuyerTransferResetRequest';
import { BuyerTransferResetResponse } from '@/models/api/buyer/BuyerTransferResetResponse';
import Icon from '@/components/Icon';
import Stepper from '@/components/Stepper';

function BuyerTransferReset(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const paymentId: string = useReduxSelector(
    (state) => state.payment.paymentId
  );
  const dispatch = useReduxDispatch();

  const submitReset = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<
      BuyerTransferResetRequest,
      BuyerTransferResetResponse
    >(`/payments/${paymentId}/buyer-transfer-reset`, {});

    if (apiRes.isErr()) {
      dispatch(setError(ErrorCodes.BUYER_TRANSFER_RESET_REQUEST));
    }

    dispatch(showLoader());
  };

  return (
    <div className="buyer-transfer-reset">
      <Stepper steps={4} active={3} complete={2} />
      <IntroImage src="/img/payment-cancelled.svg" width={244} />
      <h1>{translate('buyerTransferResetHead')}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('buyerTransferResetBody'),
        }}
      />
      <div className="buttons">
        <Button action={submitReset} loading={isLoading}>
          <Icon name="repeat" style="fas" />
          {translate('tryAgain')}
        </Button>
      </div>
    </div>
  );
}

export default BuyerTransferReset;
