import { ReactElement } from 'react';
import './SellerPayoutCompleted.scss';
import Stepper from '@/components/Stepper';
import IntroImage from '@/components/IntroImage';
import { translate } from '@/util/strings';
import Button from '@/components/Button';
import { redirectToContract } from '@/util/webApp';
import Icon from '@/components/Icon';
import { useReduxSelector } from '@/store/hooks';
import DownloadReceiptBox from '@/components/DownloadReceiptBox';
import { models } from '@swiftcourt/pay-spec';

function SellerPayoutCompleted(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);
  const buyerName: string = useReduxSelector(
    (state) => state.payment.buyerName
  );
  const receipt: models.v2.receipt.UserReceipt = useReduxSelector(
    (state) => state.payment.receipt
  );

  return (
    <div className="seller-payout-completed">
      <Stepper steps={4} active={4} complete={4} />
      <IntroImage src="/img/payment-receipt.svg" width={101} />
      <h1>{translate('seller.payoutCompletedHead')}</h1>

      <p
        dangerouslySetInnerHTML={{
          __html: translate('seller.payoutCompletedBody').replaceAll(
            '[firstName]',
            buyerName
          ),
        }}
      />

      <div className="download-receipt-container">
        {/* If receipt object is present in GET payment response for the user, then show download box for it */}
        {receipt[models.v2.receipt.ReceiptType.SERVICE] && (
          <DownloadReceiptBox type={models.v2.receipt.ReceiptType.SERVICE} />
        )}
        {receipt[models.v2.receipt.ReceiptType.TRANSACTION] && (
          <DownloadReceiptBox
            type={models.v2.receipt.ReceiptType.TRANSACTION}
          />
        )}
      </div>

      <div className="buttons">
        <Button
          action={(): void => redirectToContract(contractId)}
          primary={false}
        >
          <Icon name="arrow-left" style="fas" />
          {translate('returnToContract')}
        </Button>
      </div>
    </div>
  );
}

export default SellerPayoutCompleted;
