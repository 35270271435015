import { ReactElement } from 'react';
import { translate } from '@/util/strings';
import Stepper from '@/components/Stepper';
import IntroImage from '@/components/IntroImage';
import Button from '@/components/Button';
import { redirectToContract } from '@/util/webApp';
import { useReduxSelector } from '@/store/hooks';

function BuyerConnectingToKlarna(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);

  return (
    <div className="connecting-to-klarna">
      <Stepper steps={4} active={3} complete={2} />
      <IntroImage src="/img/hour-glass.svg" width={56} />

      <h1>{translate('connectingToKlarnaHead')}</h1>

      <div
        dangerouslySetInnerHTML={{
          __html: translate('buyer.connectingToKlarnaBody'),
        }}
      />

      <div className="buttons">
        <Button
          primary={false}
          text={translate('returnToContract')}
          action={(): void => redirectToContract(contractId)}
        />
      </div>
    </div>
  );
}

export default BuyerConnectingToKlarna;
