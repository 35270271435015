import { ReactElement } from 'react';
import Icon from './Icon';
import './Select.scss';

export interface SelectProps {
  onChange?: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  children?: React.ReactNode;
}

function Select({ onChange, children }: SelectProps): ReactElement {
  return (
    <div className="select">
      <div className="arrow">
        <Icon style="fa" name="sort-down" size="3x" />
      </div>
      <select onChange={onChange}>{children}</select>
    </div>
  );
}

export default Select;
