import { ok, err, Result } from 'neverthrow';
import { redirectToLogin } from './webApp';
import { models } from '@swiftcourt/pay-spec';
import { ConnectionError, InternalError } from '@swiftcourt/lib-error';

export const CONNECTION_REFUSED_MESSAGE = 'Connection refused';
export const RESPONSE_NOT_OK_MESSAGE = 'Response not OK';
export const NOT_FOUND_MESSAGE = 'Not found';

export type ApiRequestResult<T> = Promise<
  Result<T, InternalError | ConnectionError>
>;

export const apiBaseUrl: string =
  process.env.REACT_APP_BASE_API_URL || 'http://localhost';

export enum CONTENT_TYPE {
  JSON = 'application/json',
  PDF = 'application/pdf',
}

// endpoints which do not require the authorization header should be defined here
const openEndpoints = ['/auth/exchange'];

async function apiRequest<T>(
  path: string,
  config: RequestInit
): Promise<ApiRequestResult<T>> {
  const fullUrl = new URL(path, apiBaseUrl).href;
  const headers = new Headers(config.headers);
  const loginToken = sessionStorage.getItem('loginToken');

  if (openEndpoints.indexOf(path) === -1) {
    if (loginToken) {
      headers.set('authorization', loginToken);
    } else {
      return err(new InternalError('Login token missing'));
    }
  }

  // If content type not already set, use json as default
  if (!headers.get('content-type')) {
    headers.set('content-type', CONTENT_TYPE.JSON);
  }

  const request = new Request(fullUrl, {
    ...config,
    headers,
  });

  let response: Response;

  try {
    response = await fetch(request);
  } catch (error) {
    return err(
      new ConnectionError(CONNECTION_REFUSED_MESSAGE, { cause: error })
    );
  }

  if (!response.ok) {
    const cause = {
      statusCode: response.status,
      statusText: response.statusText,
    };
    if (response.status === 401) {
      redirectToLogin();
      return ok({} as T);
    }
    if (response.status === 404) {
      return err(new InternalError(NOT_FOUND_MESSAGE, { cause }));
    }
    return err(new InternalError(RESPONSE_NOT_OK_MESSAGE, { cause }));
  }

  if (headers.get('content-type') === CONTENT_TYPE.JSON) {
    let json;
    try {
      json = (await response.json()) as T & {
        error?: models.v1.payloadError.PayloadError;
      };
    } catch (error) {
      return err(new InternalError('Failed to parse JSON', { cause: error }));
    }

    return ok(json);
  } else if (headers.get('content-type') === CONTENT_TYPE.PDF) {
    let blob;
    try {
      blob = (await response.blob()) as T;
      return ok(blob);
    } catch (error) {
      return err(
        new InternalError('Failed to parse blob data', { cause: error })
      );
    }
  }

  const result = response.body as T;

  return ok(result);
}

export async function apiGet<T>(
  path: string,
  config?: RequestInit
): Promise<ApiRequestResult<T>> {
  const init = { method: 'get', ...config };
  return await apiRequest<T>(path, init);
}

export async function apiPost<T, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<ApiRequestResult<U>> {
  const init = { method: 'post', body: JSON.stringify(body), ...config };
  return await apiRequest<U>(path, init);
}

export async function apiPut<T, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<ApiRequestResult<U>> {
  const init = { method: 'put', body: JSON.stringify(body), ...config };
  return await apiRequest<U>(path, init);
}
