import { ReactElement, useState } from 'react';
import { PayoutPutFlowRequest } from '@/models/api/payout/PutFlowRequest';
import { PayoutPutFlowResponse } from '@/models/api/payout/PutFlowResponse';
import { apiPut } from '@/util/api';
import Stepper from '@/components/Stepper';
import IntroImage from '@/components/IntroImage';
import Button from '@/components/Button';
import PopUp from '@/components/PopUp';
import { translate } from '@/util/strings';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { showLoader } from '@/store/payment';
import './HandOver.scss';
import { goToLink, Link } from '@/util/links';

function SellerWaitingForSellerHandover(): ReactElement {
  const dispatch = useReduxDispatch();
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const paymentId: string = useReduxSelector(
    (state) => state.payment.paymentId
  );
  const buyerName: string = useReduxSelector(
    (state) => state.payment.buyerName
  );
  const language = useReduxSelector((state) => state.payment.language);

  const confirmHandover = async (): Promise<void> => {
    await confirmOuterFlow();
    setPopUpIsOpen(false);
  };

  const confirmOuterFlow = async (): Promise<void> => {
    await apiPut<PayoutPutFlowRequest, PayoutPutFlowResponse>(
      `/payments/${paymentId}/handover`,
      {}
    );
    dispatch(showLoader());
  };

  const popUpCloseHandler = (isOpen: boolean): void => {
    setPopUpIsOpen(isOpen);
  };

  return (
    <div className="hand-over">
      <Stepper steps={4} active={4} complete={3} />

      <IntroImage src="/img/handover-keys.svg" width={145} />

      <h1>{translate('seller.handOverTheProductTitle')}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('seller.handOverTheProductBody').replaceAll(
            '[firstName]',
            buyerName
          ),
        }}
      />

      <p>
        <strong>{translate('confirmBeforeClickingButton')}</strong>
      </p>
      <ul className="confirm-list">
        <li>{translate('seller.confirm1')}</li>
        <li>{translate('seller.confirm2')}</li>
      </ul>

      <PopUp onClose={popUpCloseHandler} popUpIsOpen={popUpIsOpen}>
        <h1>{translate('reConfirmHandoverHead')}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: translate('reConfirmHandoverBody'),
          }}
        />
        <div className="buttons">
          <Button text={translate('reConfirm')} action={confirmHandover} />
        </div>
      </PopUp>

      <div className="buttons">
        <Button
          text={translate('confirmHandover')}
          action={(): void => setPopUpIsOpen(!popUpIsOpen)}
        />
        <Button
          primary={false}
          action={(): void => goToLink(language, Link.HANDOVER_HELP)}
        >
          {translate('thereWasAProblem')}
        </Button>
      </div>
    </div>
  );
}

export default SellerWaitingForSellerHandover;
