import { ReactElement } from 'react';
import Button from '@/components/Button';
import { useReduxSelector } from '@/store/hooks';
import IntroImage from '@/components/IntroImage';
import { translate } from '@/util/strings';
import Icon from '@/components/Icon';
import { redirectToContract } from '@/util/webApp';

function PaymentAborted(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);

  return (
    <div className="payment-aborted">
      <IntroImage src="/img/payment-cancelled.svg" width={244} />
      <h1>{translate('abortedHead')}</h1>

      <p
        dangerouslySetInnerHTML={{
          __html: translate('abortedBody1'),
        }}
      ></p>
      <p
        dangerouslySetInnerHTML={{
          __html: translate('abortedBody2'),
        }}
      ></p>
      <p
        dangerouslySetInnerHTML={{
          __html: translate('abortedBody3'),
        }}
      ></p>

      <div className="buttons">
        <Button
          action={(): void => redirectToContract(contractId)}
          primary={false}
        >
          <Icon name="arrow-left" style="fas" />
          {translate('returnToContract')}
        </Button>
      </div>
    </div>
  );
}

export default PaymentAborted;
