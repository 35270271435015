import BankSessionExpired from '@/components/BankSessionExpired';
import { ReactElement } from 'react';

function SellerBankSessionReset(): ReactElement {
  return (
    <>
      <BankSessionExpired />
    </>
  );
}

export default SellerBankSessionReset;
