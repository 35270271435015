import { BankSessionResetRequest } from '@/models/api/outer/BankSessionResetRequest';
import { BankSessionResetResponse } from '@/models/api/outer/BankSessionResetResponse';
import { ErrorCodes } from '@/models/ErrorCodes';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { setError, showLoader } from '@/store/payment';
import { apiPut } from '@/util/api';
import { translate } from '@/util/strings';
import { ReactElement, useState } from 'react';
import Button from './Button';
import IntroImage from './IntroImage';

function BankSessionExpired(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const paymentId: string = useReduxSelector(
    (state) => state.payment.paymentId
  );
  const dispatch = useReduxDispatch();

  const restartSession = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<
      BankSessionResetRequest,
      BankSessionResetResponse
    >(`/payments/${paymentId}/bank-session-reset`, {});

    if (apiRes.isErr()) {
      dispatch(setError(ErrorCodes.BANK_SESSION_RESET_REQUEST));
    }

    dispatch(showLoader());
  };

  return (
    <div className="bank-session-expired">
      <IntroImage src="/img/hour-glass.svg" />
      <h1>{translate('sessionExpiredHead')}</h1>
      <div
        dangerouslySetInnerHTML={{ __html: translate('sessionExpiredBody') }}
      />

      <div className="buttons">
        <Button
          text={translate('tryAgain')}
          action={restartSession}
          loading={isLoading}
        />
      </div>
    </div>
  );
}

export default BankSessionExpired;
