import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { showLoader } from '@/store/payment';
import Loading from '@/views/Loading';
import KlarnaPopupError from '@/views/KlarnaPopupError';
import { ReactElement, useEffect, useState } from 'react';

declare global {
  interface Window {
    XS2A: {
      startFlow: (clientToken: string, options: object) => void;
      close: () => void;
    };
  }
}

const KLARNA_SCRIPT_URL = process.env.REACT_APP_KLARNA_SCRIPT_URL || '';

function KlarnaPopup(): ReactElement {
  const [scriptIsLoaded, setScriptIsLoaded] = useState(false);
  const [complete, setComplete] = useState(false);
  const [showError, setShowError] = useState(false);
  const dispatch = useReduxDispatch();

  const clientToken: string = useReduxSelector(
    (state) => state.payment.clientToken
  );

  const startWidget = (): void => {
    try {
      window.XS2A.startFlow(clientToken, {
        onFinished: () => {
          setComplete(true);
          setShowError(false);
          dispatch(showLoader());
        },
        onError: () => {
          handleTokenError();
        },
      });
    } catch (error) {
      handleTokenError();
    }
  };

  const handleTokenError = (): void => {
    console.error('Failed to start Klarna widget');
    setShowError(true); // @note to ensure the user get an error message.
    // To-Do: we will eventually call an endpoint which reverts the user to a place in the state machine
    // where they get a new clientToken
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = KLARNA_SCRIPT_URL;
    script.async = true;
    script.onload = (): void => {
      setScriptIsLoaded(true);
    };
    document.head.appendChild(script);
    return () => {
      window?.XS2A?.close();
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (scriptIsLoaded && clientToken) {
      startWidget();
    }
  }, [scriptIsLoaded, clientToken]);

  return (
    <div className="klarna-popup">
      {complete && <Loading />}
      {showError && <KlarnaPopupError />}
    </div>
  );
}

export default KlarnaPopup;
