import { getLanguage, translate } from '@/util/strings';
import { ReactElement, useEffect, useState } from 'react';
import './Footer.scss';
import { languageLinkMap } from '@/util/links';

function Footer(): ReactElement {
  const [language, setLanguage] = useState(getLanguage());

  useEffect(() => {
    setLanguage(getLanguage());
  }, [language]);

  return (
    <footer>
      <div className="footer-inner">
        <div className="company-info">
          Swiftcourt AB, 559084-1721, Dockplatsen 1, 211 19 Malmö, Sweden
        </div>
        <div className="company-links">
          <a href={languageLinkMap[language].contact} target="_blank">
            {translate('contactUs')}
          </a>
          |
          <a href={languageLinkMap[language].privacy} target="_blank">
            {translate('privacyPolicy')}
          </a>
          |
          <a href={languageLinkMap[language].terms} target="_blank">
            {translate('termsAndConditions')}
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
