import { ReactElement } from 'react';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import IntroImage from '@/components/IntroImage';
import Stepper from '@/components/Stepper';
import TipsBox from '@/components/TipsBox';
import { translate } from '@/util/strings';
import { redirectToContract } from '@/util/webApp';
import { useReduxSelector } from '@/store/hooks';

function SellerWaitingForBuyer(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);

  return (
    <div className="waiting-for-buyer">
      <Stepper steps={4} active={3} complete={3} />

      <IntroImage src="/img/hour-glass.svg" />

      <h1>{translate('seller.waitingForBuyerHead')}</h1>

      <div
        dangerouslySetInnerHTML={{
          __html: translate('seller.waitingForBuyerBody'),
        }}
      />

      <TipsBox
        head={translate('tipsWhileYouWait1')}
        tips={[translate('seller.tipBox1')]}
      />

      <div className="buttons">
        <Button
          action={(): void => redirectToContract(contractId)}
          primary={false}
        >
          <Icon name="arrow-left" style="fas" />
          {translate('returnToContract')}
        </Button>
      </div>
    </div>
  );
}

export default SellerWaitingForBuyer;
