// Car translations
import carDeStrings from '@/data/translations/car-de.json';
import carEnStrings from '@/data/translations/car-en.json';
import carSvStrings from '@/data/translations/car-sv.json';
import carNoStrings from '@/data/translations/car-no.json';
import carFiStrings from '@/data/translations/car-fi.json';

// Twinner translations
import twinnerDeStrings from '@/data/translations/twinner-de.json';

// Motor translations
import motorDeStrings from '@/data/translations/motor-de.json';
import motorEnStrings from '@/data/translations/motor-en.json';
import motorSvStrings from '@/data/translations/motor-sv.json';
import motorNoStrings from '@/data/translations/motor-no.json';
import motorFiStrings from '@/data/translations/motor-fi.json';

// Boat translations
import boatDeStrings from '@/data/translations/boat-de.json';
import boatEnStrings from '@/data/translations/boat-en.json';
import boatSvStrings from '@/data/translations/boat-sv.json';
import boatNoStrings from '@/data/translations/boat-no.json';
import boatFiStrings from '@/data/translations/boat-fi.json';

// Finn Boat translations
import finnBoatEnStrings from '@/data/translations/finnBoat-en.json';
import finnBoatNoStrings from '@/data/translations/finnBoat-no.json';

// Horse translations
import horseDeStrings from '@/data/translations/horse-de.json';
import horseEnStrings from '@/data/translations/horse-en.json';
import horseSvStrings from '@/data/translations/horse-sv.json';
import horseNoStrings from '@/data/translations/horse-no.json';
import horseFiStrings from '@/data/translations/horse-fi.json';

// Misc translations
import miscDeStrings from '@/data/translations/misc-de.json';
import miscEnStrings from '@/data/translations/misc-en.json';
import miscSvStrings from '@/data/translations/misc-sv.json';
import miscNoStrings from '@/data/translations/misc-no.json';
import miscFiStrings from '@/data/translations/misc-fi.json';

// Hastnet translations
import hastnetSvStrings from '@/data/translations/hastnet-sv.json';

// Bidders Highway translations
import biddersHighwayEnStrings from '@/data/translations/biddersHighway-en.json';
import biddersHighwaySvStrings from '@/data/translations/biddersHighway-sv.json';

// Findit misc
import finditMiscEnStrings from '@/data/translations/finditMisc-en.json';
import finditMiscFiStrings from '@/data/translations/finditMisc-fi.json';
import finditMiscSvStrings from '@/data/translations/finditMisc-sv.json';

// Findit motor
import finditMotorEnStrings from '@/data/translations/finditMotor-en.json';
import finditMotorFiStrings from '@/data/translations/finditMotor-fi.json';
import finditMotorSvStrings from '@/data/translations/finditMotor-sv.json';

// Heartbids
import heartbidsEnStrings from '@/data/translations/heartbids-en.json';
import heartbidsDeStrings from '@/data/translations/heartbids-de.json';

import { models } from '@swiftcourt/pay-spec';

declare interface VerticalStringData {
  car: {
    [models.v2.Language.DE]: Record<string, string>;
    [models.v2.Language.EN]: Record<string, string>;
    [models.v2.Language.SV]: Record<string, string>;
    [models.v2.Language.NO]: Record<string, string>;
    [models.v2.Language.FI]: Record<string, string>;
  };
  twinner: {
    [models.v2.Language.DE]: Record<string, string>;
    [models.v2.Language.EN]: Record<string, string>;
    [models.v2.Language.SV]: Record<string, string>;
    [models.v2.Language.NO]: Record<string, string>;
    [models.v2.Language.FI]: Record<string, string>;
  };
  motor: {
    [models.v2.Language.DE]: Record<string, string>;
    [models.v2.Language.EN]: Record<string, string>;
    [models.v2.Language.SV]: Record<string, string>;
    [models.v2.Language.NO]: Record<string, string>;
    [models.v2.Language.FI]: Record<string, string>;
  };
  boat: {
    [models.v2.Language.DE]: Record<string, string>;
    [models.v2.Language.EN]: Record<string, string>;
    [models.v2.Language.SV]: Record<string, string>;
    [models.v2.Language.NO]: Record<string, string>;
    [models.v2.Language.FI]: Record<string, string>;
  };
  finnBoat: {
    [models.v2.Language.EN]: Record<string, string>;
    [models.v2.Language.NO]: Record<string, string>;
    [models.v2.Language.DE]: Record<string, string>;
    [models.v2.Language.SV]: Record<string, string>;
    [models.v2.Language.FI]: Record<string, string>;
  };
  horse: {
    [models.v2.Language.DE]: Record<string, string>;
    [models.v2.Language.EN]: Record<string, string>;
    [models.v2.Language.SV]: Record<string, string>;
    [models.v2.Language.NO]: Record<string, string>;
    [models.v2.Language.FI]: Record<string, string>;
  };
  misc: {
    [models.v2.Language.DE]: Record<string, string>;
    [models.v2.Language.EN]: Record<string, string>;
    [models.v2.Language.SV]: Record<string, string>;
    [models.v2.Language.NO]: Record<string, string>;
    [models.v2.Language.FI]: Record<string, string>;
  };
  hastnet: {
    [models.v2.Language.DE]: Record<string, string>;
    [models.v2.Language.EN]: Record<string, string>;
    [models.v2.Language.SV]: Record<string, string>;
    [models.v2.Language.NO]: Record<string, string>;
    [models.v2.Language.FI]: Record<string, string>;
  };
  biddersHighway: {
    [models.v2.Language.DE]: Record<string, string>;
    [models.v2.Language.EN]: Record<string, string>;
    [models.v2.Language.SV]: Record<string, string>;
    [models.v2.Language.NO]: Record<string, string>;
    [models.v2.Language.FI]: Record<string, string>;
  };
  finditMisc: {
    [models.v2.Language.DE]: Record<string, string>;
    [models.v2.Language.EN]: Record<string, string>;
    [models.v2.Language.SV]: Record<string, string>;
    [models.v2.Language.NO]: Record<string, string>;
    [models.v2.Language.FI]: Record<string, string>;
  };
  finditMotor: {
    [models.v2.Language.DE]: Record<string, string>;
    [models.v2.Language.EN]: Record<string, string>;
    [models.v2.Language.SV]: Record<string, string>;
    [models.v2.Language.NO]: Record<string, string>;
    [models.v2.Language.FI]: Record<string, string>;
  };
  heartbids: {
    [models.v2.Language.DE]: Record<string, string>;
    [models.v2.Language.EN]: Record<string, string>;
    [models.v2.Language.SV]: Record<string, string>;
    [models.v2.Language.NO]: Record<string, string>;
    [models.v2.Language.FI]: Record<string, string>;
  };
}

const verticalStringData: VerticalStringData = {
  car: {
    [models.v2.Language.DE]: carDeStrings,
    [models.v2.Language.EN]: carEnStrings,
    [models.v2.Language.SV]: carSvStrings,
    [models.v2.Language.NO]: carNoStrings,
    [models.v2.Language.FI]: carFiStrings,
  },
  twinner: {
    [models.v2.Language.DE]: twinnerDeStrings,
    [models.v2.Language.EN]: {},
    [models.v2.Language.SV]: {},
    [models.v2.Language.NO]: {},
    [models.v2.Language.FI]: {},
  },
  motor: {
    [models.v2.Language.DE]: motorDeStrings,
    [models.v2.Language.EN]: motorEnStrings,
    [models.v2.Language.SV]: motorSvStrings,
    [models.v2.Language.NO]: motorNoStrings,
    [models.v2.Language.FI]: motorFiStrings,
  },
  boat: {
    [models.v2.Language.DE]: boatDeStrings,
    [models.v2.Language.EN]: boatEnStrings,
    [models.v2.Language.SV]: boatSvStrings,
    [models.v2.Language.NO]: boatNoStrings,
    [models.v2.Language.FI]: boatFiStrings,
  },
  finnBoat: {
    [models.v2.Language.EN]: finnBoatEnStrings,
    [models.v2.Language.NO]: finnBoatNoStrings,
    [models.v2.Language.DE]: {},
    [models.v2.Language.SV]: {},
    [models.v2.Language.FI]: {},
  },
  horse: {
    [models.v2.Language.DE]: horseDeStrings,
    [models.v2.Language.EN]: horseEnStrings,
    [models.v2.Language.SV]: horseSvStrings,
    [models.v2.Language.NO]: horseNoStrings,
    [models.v2.Language.FI]: horseFiStrings,
  },
  misc: {
    [models.v2.Language.DE]: miscDeStrings,
    [models.v2.Language.EN]: miscEnStrings,
    [models.v2.Language.SV]: miscSvStrings,
    [models.v2.Language.NO]: miscNoStrings,
    [models.v2.Language.FI]: miscFiStrings,
  },
  hastnet: {
    [models.v2.Language.DE]: {},
    [models.v2.Language.EN]: {},
    [models.v2.Language.SV]: hastnetSvStrings,
    [models.v2.Language.NO]: {},
    [models.v2.Language.FI]: {},
  },
  biddersHighway: {
    [models.v2.Language.DE]: {},
    [models.v2.Language.EN]: biddersHighwayEnStrings,
    [models.v2.Language.SV]: biddersHighwaySvStrings,
    [models.v2.Language.NO]: {},
    [models.v2.Language.FI]: {},
  },
  finditMisc: {
    [models.v2.Language.DE]: {},
    [models.v2.Language.EN]: finditMiscEnStrings,
    [models.v2.Language.SV]: finditMiscSvStrings,
    [models.v2.Language.NO]: {},
    [models.v2.Language.FI]: finditMiscFiStrings,
  },
  finditMotor: {
    [models.v2.Language.DE]: {},
    [models.v2.Language.EN]: finditMotorEnStrings,
    [models.v2.Language.SV]: finditMotorSvStrings,
    [models.v2.Language.NO]: {},
    [models.v2.Language.FI]: finditMotorFiStrings,
  },
  heartbids: {
    [models.v2.Language.DE]: heartbidsDeStrings,
    [models.v2.Language.EN]: heartbidsEnStrings,
    [models.v2.Language.SV]: {},
    [models.v2.Language.NO]: {},
    [models.v2.Language.FI]: {},
  },
};

export const defaultLanguage = models.v2.Language.EN;

export const defaultVertical = 'car';

export function getLanguage(): models.v2.Language {
  return (sessionStorage.language as models.v2.Language) || defaultLanguage;
}

export function getVertical(): models.v2.Vertical {
  return (sessionStorage.vertical as models.v2.Vertical) || defaultVertical;
}

export function translate(key: string): string {
  const language = getLanguage();
  const vertical = getVertical() as keyof VerticalStringData;

  let translation = key;

  if (
    typeof verticalStringData[vertical][language][`${key}`] === 'string' &&
    verticalStringData[vertical][language][`${key}`].length > 0
  ) {
    translation = verticalStringData[vertical][language][`${key}`];
  }

  return translation;
}
