import { ReactElement } from 'react';
import { translate } from '@/util/strings';
import IntroImage from '@/components/IntroImage';
import Button from '@/components/Button';
import Stepper from '@/components/Stepper';
import { redirectToContract } from '@/util/webApp';
import './BuyerWaitingForPayIn.scss';
import { useReduxSelector } from '@/store/hooks';

function BuyerWaitingForPayIn(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);

  return (
    <div className="waiting-for-pay-in">
      <Stepper steps={4} active={3} complete={2} />
      <IntroImage src="/img/hour-glass.svg" width={56} />

      <h1>{translate('moneyOnItsWayHead')}</h1>

      <div
        dangerouslySetInnerHTML={{
          __html: translate('buyer.moneyOnItsWayBody'),
        }}
      />

      <div className="buttons">
        <Button
          text={translate('returnToContract')}
          action={(): void => redirectToContract(contractId)}
          primary={false}
        />
      </div>
    </div>
  );
}

export default BuyerWaitingForPayIn;
