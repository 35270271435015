import { ReactElement } from 'react';
import { translate } from '@/util/strings';
import IntroImage from '@/components/IntroImage';
import Button from '@/components/Button';
import Stepper from '@/components/Stepper';
import { redirectToContract } from '@/util/webApp';
import { useReduxSelector } from '@/store/hooks';

function SellerCreatingEscrow(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);

  return (
    <div className="waiting-for-ais">
      <Stepper steps={4} active={2} complete={1} />

      <IntroImage src="/img/hour-glass.svg" />

      <h1>{translate('seller.creatingEscrowAccountHead')}</h1>

      <p>{translate('seller.creatingEscrowAccountBody')}</p>

      <div className="buttons">
        <Button
          text={translate('returnToContract')}
          action={(): void => redirectToContract(contractId)}
          primary={false}
        />
      </div>
    </div>
  );
}

export default SellerCreatingEscrow;
