import React, { ReactElement, useEffect, useState } from 'react';
import Stepper from '@/components/Stepper';
import IntroImage from '@/components/IntroImage';
import Button from '@/components/Button';
import { translate } from '@/util/strings';
import './BuyerConfirmPayment.scss';
import { setError, showLoader } from '@/store/payment';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { apiPut } from '@/util/api';
import { ConfirmPaymentResponse } from '@/models/api/buyer/ConfirmPaymentResponse';
import { ErrorCodes } from '@/models/ErrorCodes';
import { getCurrencySymbol } from '@/util/currency';
import { ConfirmPaymentRequest } from '@/models/api/buyer/ConfirmPaymentRequest';
import { models } from '@swiftcourt/pay-spec';
import Select from '@/components/Select';

const totalAmountKey =
  models.v2.getPayment.TransactionListItemName.TOTAL_AMOUNT;
const remainingAmountKey =
  models.v2.getPayment.TransactionListItemName.REMAINING_AMOUNT;

const moneyOriginOptions: Record<string, string> =
  models.v2.bankInformation.moneyOrigin;

function BuyerConfirmPayment(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [isPartiallyPaid, setIsPartiallyPaid] = useState(false);
  const [moneyOrigin, setMoneyOrigin] = useState(
    models.v2.bankInformation.moneyOrigin.NOT_SET
  );
  const paymentId: string = useReduxSelector(
    (state) => state.payment.paymentId
  );
  const dispatch = useReduxDispatch();
  const product = useReduxSelector((state) => state.payment.product);
  const sellerName: string = useReduxSelector(
    (state) => state.payment.sellerName
  );
  const transactionList = useReduxSelector(
    (state) => state.payment.transactionList
  );
  const currency = useReduxSelector((state) => state.payment.currency);

  const handleChangeMoneyOrigin = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setMoneyOrigin(event.target.value as models.v2.bankInformation.moneyOrigin);
  };

  const confirmPayment = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<ConfirmPaymentRequest, ConfirmPaymentResponse>(
      `/payments/${paymentId}/payment-confirmation`,
      { moneyOrigin }
    );

    if (apiRes.isErr()) {
      dispatch(setError(ErrorCodes.BUYER_CONFIRM_PAYMENT_REQUEST));
    }

    dispatch(showLoader());
  };

  // Set totalAmount and remainingAmount values from transaction list
  useEffect(() => {
    const totalAmountItem = transactionList.find(
      (i) => i.name === totalAmountKey
    );
    if (totalAmountItem !== undefined) {
      setTotalAmount(totalAmountItem.value);
    }

    const remainingAmountItem = transactionList.find(
      (i) => i.name === remainingAmountKey
    );
    if (remainingAmountItem !== undefined) {
      setRemainingAmount(remainingAmountItem.value);
    }
  }, [transactionList]);

  // Set isPartiallyPaid flag based on remaining and total amounts
  useEffect(() => {
    if (remainingAmount > 0 && remainingAmount < totalAmount) {
      setIsPartiallyPaid(true);
      return;
    }

    setIsPartiallyPaid(false);
  }, [totalAmount, remainingAmount]);

  return (
    <div className="confirm-payment">
      <Stepper steps={4} active={3} complete={2} />
      <IntroImage src="/img/phone-with-piggy-bank.svg" width={136} />
      <h1>{translate('buyer.confirmPaymentHead')}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('buyer.confirmPaymentBody').replaceAll(
            '[firstName]',
            sellerName
          ),
        }}
      />

      <div className="confirm-payment-summary">
        <table>
          <thead>
            <tr>
              <th colSpan={2}>{translate('buyer.productTableHead')}</th>
            </tr>
          </thead>
          <tbody>
            {product.data.map((item) => (
              <tr key={item.name}>
                <td>{translate(`product.data.${item.name}`)}</td>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {!isPartiallyPaid && (
          <table>
            <thead>
              <tr>
                <th colSpan={2}>{translate('buyer.toBePaidTableHead')}</th>
              </tr>
            </thead>
            <tbody>
              {
                // Remaining amount should not be shown if payment is not partially paid
                transactionList
                  .filter((i) => i.name !== remainingAmountKey)
                  .map((item) => (
                    <tr key={item.name} className="summary-row">
                      <td>{translate(`buyer.transaction.${item.name}`)}</td>
                      <td>
                        {getCurrencySymbol(currency)}{' '}
                        {item.value.toLocaleString()}
                      </td>
                    </tr>
                  ))
              }
            </tbody>
          </table>
        )}
        {isPartiallyPaid && (
          <table>
            <thead>
              <tr>
                <th colSpan={2}>{translate('buyer.confirmAmountTableHead')}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="summary-row">
                <td>{translate('buyer.confirmAmountTableRow1')}</td>
                <td>
                  {getCurrencySymbol(currency)} {totalAmount.toLocaleString()}
                </td>
              </tr>
              <tr className="summary-row">
                <td>{translate('buyer.confirmAmountTableRow2')}</td>
                <td>
                  {getCurrencySymbol(currency)}{' '}
                  {(totalAmount - remainingAmount).toLocaleString()}
                </td>
              </tr>
              <tr className="summary-row">
                <td>{translate('buyer.confirmAmountTableRow3')}</td>
                <td>
                  {getCurrencySymbol(currency)}{' '}
                  {remainingAmount.toLocaleString()}
                </td>
              </tr>
            </tbody>
          </table>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: translate('buyer.moneyOriginBody1'),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: translate('buyer.moneyOriginBody2'),
          }}
        />
        <Select onChange={handleChangeMoneyOrigin}>
          <option value=""></option>
          {Object.keys(moneyOriginOptions)
            .filter((originKey) => originKey !== 'NOT_SET')
            .map((originKey) => (
              <option key={originKey} value={moneyOriginOptions[originKey]}>
                {translate(`buyer.moneyOrigin.${originKey}`)}
              </option>
            ))}
        </Select>
      </div>

      <div className="buttons">
        <Button
          text={translate('buyer.submit')}
          action={confirmPayment}
          loading={isLoading}
          disabled={
            moneyOrigin === models.v2.bankInformation.moneyOrigin.NOT_SET
          }
        />
      </div>
    </div>
  );
}

export default BuyerConfirmPayment;
