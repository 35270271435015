import { ReactElement, useState } from 'react';
import Button from '@/components/Button';
import IntroImage from '@/components/IntroImage';
import Stepper from '@/components/Stepper';
import { translate } from '@/util/strings';
import BankSelector from '@/components/BankSelector';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { apiPut } from '@/util/api';
import { setError, bankChoiceDone } from '@/store/payment';
import { OuterPutBankRequest } from '@/models/api/outer/PutBankRequest';
import { OuterPutBankResponse } from '@/models/api/outer/PutBankResponse';
import TipsBox from '@/components/TipsBox';
import { ErrorCodes } from '@/models/ErrorCodes';
import './BuyerChooseBankV2.scss';

function BuyerChooseBankV2(): ReactElement {
  const paymentId = useReduxSelector((state) => state.payment.paymentId);
  const choiceDone = useReduxSelector(bankChoiceDone);
  const bankBranchChoice = useReduxSelector(
    (state) => state.payment.bankBranchChoice
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useReduxDispatch();

  const submitBankChoice = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<OuterPutBankRequest, OuterPutBankResponse>(
      `v2/payments/${paymentId}/bank`,
      { bankChoice: bankBranchChoice }
    );
    if (!apiRes.isOk()) {
      dispatch(setError(ErrorCodes.BANK_CHOICE_V2_REQUEST));
    }
  };

  return (
    <div className="buyer-choose-bank-v2">
      <Stepper steps={4} active={3} complete={2} />
      <IntroImage src="/img/phone-with-piggy-bank.svg" width={136} />
      <h1>{translate('buyer.chooseBankV2Head')}</h1>

      <div className="bank-select-wrapper">
        <h4>{translate('buyer.chooseBankV2ListHead')}</h4>

        <BankSelector />
      </div>

      <div className="buttons">
        <Button
          text={translate('buyer.submit')}
          action={submitBankChoice}
          loading={isLoading}
          disabled={!choiceDone}
        />
        {/* @todo Implement once choose payment method page is done */}
        {/* <Button text={translate('buyer.previousPage')} primary={false} /> */}
      </div>

      <TipsBox
        head={translate('buyer.chooseBankV2TipsHead')}
        tips={[translate('buyer.chooseBankV2TipsBody')]}
      />
    </div>
  );
}

export default BuyerChooseBankV2;
