import { ReactElement, useState } from 'react';
import { models } from '@swiftcourt/pay-spec';
import Button from '@/components/Button';
import IntroImage from '@/components/IntroImage';
import Select from '@/components/Select';
import Icon from '@/components/Icon';
import { translate } from '@/util/strings';
import { useReduxSelector, useReduxDispatch } from '@/store/hooks';
import { redirectToContract } from '@/util/webApp';
import { setError, showLoader } from '@/store/payment';
import { apiPut } from '@/util/api';
import { ErrorCodes } from '@/models/ErrorCodes';
import { BuyerNameMismatchReasonRequest } from '@/models/api/buyer/BuyerNameMismatchReasonRequest';
import { BuyerNameMismatchReasonResponse } from '@/models/api/buyer/BuyerNameMismatchReasonResponse';
import './SellerChooseBank.scss';
import './BuyerNameMismatch.scss';

// Used to limit the number of words in the text area
const MAX_WORD_COUNT = 500;

function BuyerNameMismatch(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState(
    models.v3.nameCheck.NameMismatchReason.NOT_SET
  );
  const [otherText, setOtherText] = useState('');
  const paymentId: string = useReduxSelector(
    (state) => state.payment.paymentId
  );
  const dispatch = useReduxDispatch();

  const submitReason = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<
      BuyerNameMismatchReasonRequest,
      BuyerNameMismatchReasonResponse
    >(`/payments/${paymentId}/buyer-name-mismatch-reason`, {
      reason: selectedReason,
      ...(otherText && { comment: otherText }),
    });

    if (apiRes.isErr()) {
      dispatch(setError(ErrorCodes.BUYER_NAME_MISMATCH_REASON_REQUEST));
    }

    dispatch(showLoader());
  };

  const handleChangeReason = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setSelectedReason(
      event.target.value as models.v3.nameCheck.NameMismatchReason
    );
  };

  const handleOtherText = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const inputText = event.target.value;
    if (inputText.length <= MAX_WORD_COUNT) {
      setOtherText(inputText);
    }
  };

  return (
    <div className="buyer-name-mismatch">
      <IntroImage src="/img/hour-glass.svg" width={136} />
      <h1>{translate('buyer.nameCheck.moreInformationNeededTitle')}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('buyer.nameCheck.waitingForNameMismatchBody'),
        }}
      />

      <Select onChange={handleChangeReason}>
        <option value=""></option>
        {Object.entries(models.v3.nameCheck.NameMismatchReason).map(
          ([key, value]) =>
            value !== models.v3.nameCheck.NameMismatchReason.NOT_SET && (
              <option key={key} value={key}>
                {translate(`buyer.nameCheck.${value}`)}
              </option>
            )
        )}
      </Select>

      {selectedReason === models.v3.nameCheck.NameMismatchReason.OTHER && (
        <div className="otherReasonText">
          <label>{translate('buyer.nameCheck.otherReason')}</label>
          <textarea
            cols={20}
            rows={5}
            onChange={handleOtherText}
            value={otherText}
          ></textarea>
        </div>
      )}

      <div className="buttonsGroup">
        <div className="buttons">
          <Button
            action={submitReason}
            loading={isLoading}
            disabled={
              selectedReason === '' ||
              (selectedReason ===
                models.v3.nameCheck.NameMismatchReason.OTHER &&
                otherText === '')
            }
          >
            {translate('buyer.nameCheck.submit')}
            <Icon name="arrow-right" style="fas" />
          </Button>
        </div>

        <div className="buttons">
          <Button
            action={(): void => redirectToContract(contractId)}
            primary={false}
          >
            <Icon name="arrow-left" style="fas" />
            {translate('returnToContract')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BuyerNameMismatch;
