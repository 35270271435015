import { ReactElement } from 'react';
import Button from '@/components/Button';
import { useReduxSelector } from '@/store/hooks';
import './Error.scss';
import IntroImage from '@/components/IntroImage';
import { translate } from '@/util/strings';
import Icon from '@/components/Icon';
import { redirectToContract } from '@/util/webApp';
import { goToLink, Link } from '@/util/links';

function Error(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);
  const error = useReduxSelector((state) => state.payment.error);
  const language = useReduxSelector((state) => state.payment.language);

  return (
    <div className="error">
      <IntroImage src="/img/chat-bubble.svg" width={244} />
      <h1>{translate('errorHead')}</h1>

      <div
        dangerouslySetInnerHTML={{
          __html: translate('errorBody'),
        }}
      />

      {error && (
        <div className="error-details">
          {translate('errorCode')}: <span className="error-id">{error}</span>
        </div>
      )}

      <div className="buttons">
        <Button
          action={(): void => goToLink(language, Link.CONTACT)}
          text={translate('contactSupportTeam')}
        />
        <Button
          action={(): void => redirectToContract(contractId)}
          primary={false}
        >
          <Icon name="arrow-left" style="fas" />
          {translate('returnToContract')}
        </Button>
      </div>
    </div>
  );
}

export default Error;
