import { ReactElement } from 'react';
import Stepper from '@/components/Stepper';
import IntroImage from '@/components/IntroImage';
import Button from '@/components/Button';
import { translate } from '@/util/strings';
import { redirectToContract } from '@/util/webApp';
// disabling for now to avoid linting; add import again when we add infobox back
//import TipsBox from '@/components/TipsBox';
import Icon from '@/components/Icon';
import { useReduxSelector } from '@/store/hooks';
import './HandOver.scss';

function BuyerWaitingForSellerHandover(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);
  const sellerName: string = useReduxSelector(
    (state) => state.payment.sellerName
  );

  return (
    <div className="hand-over">
      <Stepper steps={4} active={4} complete={3} />
      <IntroImage src="/img/hour-glass.svg" width={56} />
      <h1>{translate('waitingForHandoverHead')}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('buyer.waitingForHandoverBody').replaceAll(
            '[firstName]',
            sellerName
          ),
        }}
      />

      {/* NOTE: Commented out, check @MEP-717  */}
      {/* <TipsBox */}
      {/*   head={translate('tipsWhileYouWait')} */}
      {/*   tips={[ */}
      {/*     translate('buyer.tip1'), */}
      {/*     translate('buyer.tip2'), */}
      {/*     translate('buyer.tip3'), */}
      {/*     translate('buyer.tip4'), */}
      {/*   ]} */}
      {/* /> */}

      <div className="buttons">
        <Button
          primary={false}
          action={(): void => redirectToContract(contractId)}
        >
          <Icon name="arrow-left" />
          {translate('returnToContract')}
        </Button>
      </div>
    </div>
  );
}

export default BuyerWaitingForSellerHandover;
