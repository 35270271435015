import { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import StepperStep from './StepperStep';
import './Stepper.scss';

interface StepperProps {
  steps: number;
  active: number;
  complete: number;
}

function Stepper({
  steps = 4,
  active = 1,
  complete = 0,
}: StepperProps): ReactElement {
  const [completedWidth, setCompletedWidth] = useState(0);
  const className = classNames({
    stepper: true,
  });

  useEffect(() => {
    const stepRatio = 100 / steps;
    const percentComplete = active / steps;
    setCompletedWidth(
      complete === 0
        ? 0
        : active === steps
        ? 100
        : percentComplete * 100 - stepRatio * 0.5
    );
  }, [steps, active]);

  return (
    <div className={className}>
      <div className="line"></div>
      <div
        className="line-complete"
        style={{ width: `${completedWidth}%` }}
      ></div>
      <div className="steps">
        {[...Array.from({ length: steps })].map((_, i) => (
          <StepperStep
            key={i}
            active={active === i + 1}
            complete={
              i + 1 < active || (active === complete && steps === complete)
            }
          />
        ))}
      </div>
    </div>
  );
}

export default Stepper;
