import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import './Icon.scss';

interface IconProps {
  name: string;
  style?: string;
  size?: FontAwesomeIconProps['size'];
  rotation?: FontAwesomeIconProps['rotation'];
  spin?: FontAwesomeIconProps['spin'];
  border?: FontAwesomeIconProps['border'];
  pull?: FontAwesomeIconProps['pull'];
  flip?: FontAwesomeIconProps['flip'];
}

const Icon = ({
  name,
  style = 'fas',
  size,
  rotation,
  spin,
  border,
  pull,
  flip,
}: IconProps): ReactElement => {
  return (
    <FontAwesomeIcon
      className="icon"
      icon={[style as IconPrefix, name as IconName]}
      size={size}
      rotation={rotation}
      spin={spin}
      border={border}
      pull={pull}
      flip={flip}
    />
  );
};

export default Icon;
