import React, { ReactElement, useEffect, useState } from 'react';
import Stepper from '@/components/Stepper';
import IntroImage from '@/components/IntroImage';
import Button from '@/components/Button';
import { translate } from '@/util/strings';
import { setError, showLoader } from '@/store/payment';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { apiPut } from '@/util/api';
import { ErrorCodes } from '@/models/ErrorCodes';
import { NoBodyRequest } from '@/models/api/common/NoBodyRequest';
import { NoBodyResponse } from '@/models/api/common/NoBodyResponse';
import './BuyerIbanPayment.scss';
import Checkbox from '@/components/Checkbox';
import TipsBox from '@/components/TipsBox';
import PopUp from '@/components/PopUp';
import { models } from '@swiftcourt/pay-spec';
import { getCurrencySymbol } from '@/util/currency';

function BuyerIbanPayment(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const paymentId: string = useReduxSelector(
    (state) => state.payment.paymentId
  );
  const transactionList: models.v2.getPayment.TransactionListItem[] =
    useReduxSelector((state) => state.payment.transactionList);
  const currency = useReduxSelector((state) => state.payment.currency);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const remainingAmountKey =
    models.v2.getPayment.TransactionListItemName.REMAINING_AMOUNT;
  const ibanPayment: models.v1.IbanPayment = useReduxSelector(
    (state) => state.payment.ibanPayment
  );
  const [accountHolderNameConfirmed, setAccountHolderNameConfirmed] =
    useState(false);
  const [transferConfirmed, setTransferConfirmed] = useState(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const dispatch = useReduxDispatch();

  // Set remaining amount
  useEffect(() => {
    const remainingAmountItem = transactionList.find(
      (i) => i.name === remainingAmountKey
    );
    if (remainingAmountItem !== undefined) {
      setRemainingAmount(remainingAmountItem.value);
    }
  }, [transactionList]);

  const getAccountNumberLabel = (
    accountType: models.v1.AccountType
  ): string => {
    switch (accountType) {
      case models.v1.AccountType.IBAN:
        return 'buyer.ibanPaymentIban';
      case models.v1.AccountType.BBAN:
        return 'buyer.ibanPaymentBban';
      case models.v1.AccountType.SE_BG:
        return 'buyer.ibanPaymentSeBankGiro';
      default:
        return '';
    }
  };

  /**
   * IBAN displaying format: XXXX XXXX XXXX XXXX XXXX XXXX (group by 4 chars)
   * BBAN displaying format: XXXX XXXXXXX (first 4 + space + rest)
   * SE-BG displaying format: XXXX-XXXX (first 4-last 4)

   * @param accountType
   * @param accountNumber
   */
  const formatAccountNumberValue = (
    accountType: models.v1.AccountType,
    accountNumber: string
  ): string => {
    switch (accountType) {
      case models.v1.AccountType.IBAN:
        // eslint-disable-next-line no-case-declarations
        const ibanChunks = accountNumber.match(/.{1,4}/g) || [];
        return ibanChunks.join(' ');
      case models.v1.AccountType.BBAN:
        return accountNumber.slice(0, 4) + ' ' + accountNumber.slice(4);
      case models.v1.AccountType.SE_BG:
        // eslint-disable-next-line no-case-declarations
        const seBgChunks = accountNumber.match(/.{1,4}/g) || [];
        return seBgChunks.join('-');
      default:
        return accountNumber;
    }
  };

  const toggleAccountHolderNameConfirmed = (): void => {
    setAccountHolderNameConfirmed(!accountHolderNameConfirmed);
  };

  const toggleTransferConfirmed = (): void => {
    setTransferConfirmed(!transferConfirmed);
  };

  const popUpConfirmHandler = async (): Promise<void> => {
    await confirmIbanPayment();
    setPopUpIsOpen(false);
  };

  const confirmIbanPayment = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<NoBodyRequest, NoBodyResponse>(
      `/payments/${paymentId}/iban-payment/done`,
      {}
    );

    if (apiRes.isErr()) {
      dispatch(setError(ErrorCodes.CONFIRM_IBAN_PAYMENT_REQUEST));
    }

    dispatch(showLoader());
  };

  const abortIbanPayment = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<NoBodyRequest, NoBodyResponse>(
      `/payments/${paymentId}/iban-payment/abort`,
      {}
    );

    if (apiRes.isErr()) {
      dispatch(setError(ErrorCodes.ABORT_IBAN_PAYMENT_REQUEST));
    }

    dispatch(showLoader());
  };

  return (
    <div className="buyer-iban-payment">
      <Stepper steps={4} active={3} complete={2} />
      <IntroImage src="/img/phone-with-piggy-bank.svg" width={136} />
      <div
        dangerouslySetInnerHTML={{
          __html: translate('buyer.ibanPaymentBody'),
        }}
      />
      <div className="iban-payment-summary">
        <table>
          <tbody>
            <tr className="summary-row">
              <td>{translate('buyer.ibanPaymentRecipient')}</td>
              <td>{ibanPayment.recipientName}</td>
            </tr>
            <tr className="summary-row">
              <td>
                {translate(getAccountNumberLabel(ibanPayment.accountType))}
              </td>
              <td>
                {formatAccountNumberValue(
                  ibanPayment.accountType,
                  ibanPayment.accountNumber
                )}
              </td>
            </tr>
            <tr className="summary-row">
              <td>{translate('buyer.ibanPaymentReference')}</td>
              <td>{ibanPayment.paymentReference}</td>
            </tr>
            <tr className="summary-row">
              <td>{translate('buyer.ibanPaymentBic')}</td>
              <td>{ibanPayment.bic}</td>
            </tr>
            <tr className="summary-row">
              <td
                dangerouslySetInnerHTML={{
                  __html: translate('buyer.ibanPaymentSumTotal'),
                }}
              ></td>
              <td>
                {getCurrencySymbol(currency)} {remainingAmount.toLocaleString()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <Checkbox
          id="account-holder-name-confirm-checkbox"
          label={translate('buyer.ibanPaymentCheckbox1')}
          value={accountHolderNameConfirmed}
          onChange={toggleAccountHolderNameConfirmed}
        />
      </div>
      <div>
        <Checkbox
          id="transfer-confirm-checkbox"
          label={translate('buyer.ibanPaymentCheckbox2')}
          value={transferConfirmed}
          onChange={toggleTransferConfirmed}
        />
      </div>
      <div className="buttons">
        <Button
          text={translate('buyer.ibanPaymentConfirm')}
          disabled={!accountHolderNameConfirmed || !transferConfirmed}
          action={(): void => setPopUpIsOpen(!popUpIsOpen)}
          loading={isLoading}
        />
        <Button
          text={translate('buyer.ibanPaymentAbort')}
          action={abortIbanPayment}
          loading={isLoading}
          primary={false}
        />
      </div>
      <TipsBox
        head={translate('buyer.ibanPaymentTipsHead')}
        tips={[translate('buyer.ibanPaymentTipsBody')]}
      />

      <PopUp
        onClose={(): void => setPopUpIsOpen(false)}
        popUpIsOpen={popUpIsOpen}
      >
        <h1>{translate('buyer.ibanPaymentConfirmModalHead')}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: translate('buyer.ibanPaymentConfirmModalBody'),
          }}
        />
        <div className="buttons">
          <Button
            text={translate('buyer.ibanPaymentConfirmModalOptionYes')}
            action={popUpConfirmHandler}
          />
          <Button
            text={translate('buyer.ibanPaymentConfirmModalOptionNo')}
            action={(): void => setPopUpIsOpen(false)}
            primary={false}
          />
        </div>
      </PopUp>
    </div>
  );
}

export default BuyerIbanPayment;
