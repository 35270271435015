import KlarnaPopup from '@/components/KlarnaPopup';
import { ReactElement } from 'react';

function SellerKlarnaPopup(): ReactElement {
  return (
    <>
      <KlarnaPopup />
    </>
  );
}

export default SellerKlarnaPopup;
