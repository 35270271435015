import { ReactElement, useState } from 'react';
import Button from '@/components/Button';
import IntroCard from '@/components/IntroCard';
import TermsCheckBox from '@/components/TermsCheckbox';
import { translate } from '@/util/strings';
import { SellerAcceptTermsRequest } from '@/models/api/seller/AcceptTermsRequest';
import { SellerAcceptTermsResponse } from '@/models/api/seller/AcceptTermsResponse';
import { apiPut } from '@/util/api';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { setError } from '@/store/payment';
import { showLoader } from '@/store/payment';
import './SellerHowItWorks.scss';
import Icon from '@/components/Icon';
import { ErrorCodes } from '@/models/ErrorCodes';

function SellerHowItWorks(): ReactElement {
  const paymentId: string = useReduxSelector(
    (state) => state.payment.paymentId
  );
  const dispatch = useReduxDispatch();
  const vertical = useReduxSelector((state) => state.payment.vertical);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const acceptTermsHandler = (): void => {
    setTermsAgreed(!termsAgreed);
  };

  const acceptTermsAndConditions = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<
      SellerAcceptTermsRequest,
      SellerAcceptTermsResponse
    >(`/payments/${paymentId}/terms`, {});

    if (apiRes.isErr()) {
      dispatch(setError(ErrorCodes.ACCEPT_TERMS_REQUEST));
    }

    dispatch(showLoader());
  };

  return (
    <div className="how-it-works">
      <h1>{translate('introHead')}</h1>

      {[1, 2, 3, 4].map((step) => (
        <IntroCard
          key={step}
          image={`/${vertical}/img/seller-how-it-works-${step}.svg`}
          head={translate(`seller.step${step}Head`)}
          body={translate(`seller.step${step}Body`)}
        />
      ))}

      <TermsCheckBox
        content={translate('acceptTerms')}
        value={termsAgreed}
        onChange={acceptTermsHandler}
      />
      <div className="button-container">
        <Button
          disabled={!termsAgreed}
          action={acceptTermsAndConditions}
          loading={isLoading}
        >
          {translate('getStarted')}
          <Icon name="arrow-right" style="fas" />
        </Button>
      </div>
    </div>
  );
}

export default SellerHowItWorks;
