import { ReactElement } from 'react';
import { translate } from '@/util/strings';
import IntroImage from '@/components/IntroImage';
import Stepper from '@/components/Stepper';
import Button from '@/components/Button';
import { redirectToContract } from '@/util/webApp';
import { useReduxSelector } from '@/store/hooks';

function SellerConnectingToKlarna(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);

  return (
    <div className="waiting-for-client">
      <Stepper steps={4} active={2} complete={1} />

      <IntroImage src="/img/hour-glass.svg" />

      <h1>{translate('connectingToKlarnaHead')}</h1>

      <div
        dangerouslySetInnerHTML={{
          __html: translate('seller.connectingToKlarnaBody'),
        }}
      />

      <div className="buttons">
        <Button
          primary={false}
          text={translate('returnToContract')}
          action={(): void => redirectToContract(contractId)}
        />
      </div>
    </div>
  );
}

export default SellerConnectingToKlarna;
