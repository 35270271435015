import { BaseSyntheticEvent, ReactElement, useEffect, useState } from 'react';
import './BankSelector.scss';
import classNames from 'classnames';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import {
  setBankBranchChoice,
  setBankChoice,
  setBankBranches,
} from '@/store/payment';
import BankBranchSelector from '@/components/BankBranchSelector';
import Button from '@/components/Button';
import { translate } from '@/util/strings';
import { apiGet } from '@/util/api';

type SyntheticEvent<T = Element, E = Event> = BaseSyntheticEvent<
  E,
  EventTarget & T,
  EventTarget
>;

export interface BankBranchSubset {
  id: string;
  group: string;
  name: string;
}

const MAX_VISIBLE = 5;

function BankSelector(): ReactElement {
  const [showBranchSelector, setShowBranchSelector] = useState(false);
  const [filteredBanks, setFilteredBanks] = useState<string[]>([]);
  const bankChoice = useReduxSelector((state) => state.payment.bankChoice);
  const country = useReduxSelector((state) => state.payment.country);
  const paymentId = useReduxSelector((state) => state.payment.paymentId);
  const bankBranches = useReduxSelector((state) => state.payment.bankBranches);
  const dispatch = useReduxDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  const getBankBranches = async (): Promise<void> => {
    const apiRes = await apiGet<BankBranchSubset[]>(
      `payments/${paymentId}/banks`
    );

    if (apiRes.isOk()) {
      dispatch(setBankBranches(apiRes.value));
    }
  };

  const banks = [...new Set(bankBranches.map((item) => item.group))].sort(
    (a, b) => (a > b ? 1 : b > a ? -1 : 0)
  );

  const selectBank = (bank: string): void => {
    dispatch(setBankChoice(bank));
  };

  const clearBankChoice = (): void => {
    dispatch(setBankChoice(''));
    dispatch(setBankBranchChoice(''));
  };

  const hasBranches = (bank: string): boolean => {
    return bankBranches.filter((branch) => branch.group === bank).length > 0;
  };

  const imageName = (bank: string): string => {
    return bank
      .replace(/ /g, '_')
      .toLowerCase()
      .replace(/[^0-9a-z-_]/g, '');
  };

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    getBankBranches();
  }, []);

  useEffect(() => {
    !!banks.length &&
      setFilteredBanks(
        banks.filter((bank) =>
          bank.toLowerCase().match(`${searchQuery.toLowerCase()}`)
        )
      );
  }, [searchQuery]);

  useEffect(() => {
    const needsBranch =
      bankBranches.filter((branch) => branch.group === bankChoice).length > 1;
    const useBranchSelector = !!bankChoice && needsBranch;
    setShowBranchSelector(useBranchSelector);

    const filteredBanks = banks.filter(
      (bank) => !useBranchSelector || bank === bankChoice
    );

    setFilteredBanks(filteredBanks);

    // all banks exist in bankBranches, even those with no sub-branches;
    // banks without sub-branches will always have one branch in the list
    // it still needs to be set, since it is the ID used, but we do it automatically
    if (!needsBranch) {
      const branch = bankBranches.filter(
        (branch) => branch.group === bankChoice
      );
      if (branch.length === 1) {
        dispatch(setBankBranchChoice(branch[0].id));
      }
    }
  }, [bankChoice, bankBranches]);

  return (
    <div
      className={classNames({
        'bank-selector': true,
        '--showing-branches': showBranchSelector,
      })}
    >
      {!showBranchSelector && (
        <div className="branch-search">
          <div>{translate('searchForBank')}</div>
          <input type="search" onChange={updateSearch} />
        </div>
      )}
      {showBranchSelector && (
        <div className="back-to-banks">
          <Button
            text={translate('back')}
            action={clearBankChoice}
            primary={false}
          />
        </div>
      )}
      <ul
        className={classNames({
          overflow: filteredBanks.length > MAX_VISIBLE,
        })}
        style={{
          ['--num-banks' as string]: Math.min(
            filteredBanks.length,
            MAX_VISIBLE
          ),
        }}
      >
        {filteredBanks.map((bank) => (
          <li
            key={bank}
            className={classNames({
              selected: bankChoice === bank,
            })}
            onClick={(): void => {
              selectBank(bank);
            }}
          >
            <span className="bank-logo-wrapper">
              <img src="/img/bank/bank.svg" className="bank-logo-fallback" />
              <img
                src={`/img/bank/${country}/${imageName(bank)}.png`}
                onError={(e: SyntheticEvent<HTMLImageElement, Event>): void => {
                  e.currentTarget.style.opacity = '0';
                }}
                className="bank-logo"
              />
            </span>
            {bank}
          </li>
        ))}
      </ul>
      {filteredBanks.length === 0 && (
        <div className="no-banks-found">{translate('noBanksFound')}</div>
      )}
      {showBranchSelector && hasBranches(bankChoice) && (
        <BankBranchSelector banks={bankBranches} />
      )}
    </div>
  );
}

export default BankSelector;
