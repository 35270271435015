import { models } from '@swiftcourt/pay-spec';

export function getCurrencySymbol(currency: models.v2.Currency): string {
  switch (currency) {
    case models.v2.Currency.EUR:
      return '€';
    case models.v2.Currency.SEK:
    case models.v2.Currency.NOK:
      return currency;
  }

  return 'N/A';
}
