import { ReactElement } from 'react';
import './Checkbox.scss';

declare interface CheckboxProps {
  id: string;
  label: string;
  value: boolean;
  onChange: () => void;
}

function Checkbox({ id, label, value, onChange }: CheckboxProps): ReactElement {
  return (
    <div className="checkbox">
      <div>
        <input type="checkbox" id={id} checked={value} onChange={onChange} />
      </div>
      <div>
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  );
}

export default Checkbox;
