import { ReactElement } from 'react';
import Button from '@/components/Button';
import { useReduxSelector } from '@/store/hooks';
import './SellerKycProcessing.scss';
import IntroImage from '@/components/IntroImage';
import { translate } from '@/util/strings';
import { redirectToContract } from '@/util/webApp';
import Icon from '@/components/Icon';

function SellerKycProcessing(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);

  return (
    <div className="seller-kyc-processing">
      <IntroImage src="/img/hour-glass.svg" width={56} />
      <h1>{translate('seller.processingYourRequestHead')}</h1>

      <div
        dangerouslySetInnerHTML={{
          __html: translate('seller.processingYourRequestBody'),
        }}
      />

      <div className="buttons">
        <Button
          action={(): void => redirectToContract(contractId)}
          primary={false}
        >
          <Icon name="arrow-left" style="fas" />
          {translate('returnToContract')}
        </Button>
      </div>
    </div>
  );
}

export default SellerKycProcessing;
