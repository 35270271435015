import { ReactElement, useState } from 'react';
import Button from '@/components/Button';
import IntroImage from '@/components/IntroImage';
import Stepper from '@/components/Stepper';
import { translate } from '@/util/strings';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { setError, showLoader } from '@/store/payment';
import './SellerConfirmProduct.scss';
import { apiPut } from '@/util/api';
import { SellerConfirmProductRequest } from '@/models/api/seller/ConfirmProductRequest';
import { SellerConfirmProductResponse } from '@/models/api/seller/ConfirmProductResponse';
import { ErrorCodes } from '@/models/ErrorCodes';
import { getCurrencySymbol } from '@/util/currency';
import { goToLink, Link } from '@/util/links';

function SellerConfirmProduct(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const paymentId: string = useReduxSelector(
    (state) => state.payment.paymentId
  );
  const dispatch = useReduxDispatch();
  const product = useReduxSelector((state) => state.payment.product);
  const transactionList = useReduxSelector(
    (state) => state.payment.transactionList
  );
  const currency = useReduxSelector((state) => state.payment.currency);
  const language = useReduxSelector((state) => state.payment.language);

  const confirmProduct = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<
      SellerConfirmProductRequest,
      SellerConfirmProductResponse
    >(`/payments/${paymentId}/product-confirmation`, {});

    if (apiRes.isErr()) {
      dispatch(setError(ErrorCodes.CONFIRM_PRODUCT_REQUEST));
    }

    dispatch(showLoader());
  };

  return (
    <div className="confirm-product">
      <Stepper steps={4} active={1} complete={0} />
      <IntroImage src="/img/car-checked.svg" width={248} />
      <h1>{translate('seller.confirmProductHead')}</h1>
      <div>{translate('seller.confirmProductBody')}</div>

      <div className="product-summary">
        <table>
          <thead>
            <tr>
              <th colSpan={2}>{translate('seller.confirmProductTableHead')}</th>
            </tr>
          </thead>
          <tbody>
            {product.data.map((item) => (
              <tr key={item.name}>
                <td>{translate(`product.data.${item.name}`)}</td>
                <td>{item.value}</td>
              </tr>
            ))}
            {translate('seller.confirmProductTransactionHead') !==
              'seller.confirmProductTransactionHead' && (
              <tr className="transaction-head-row">
                <th colSpan={2}>
                  {translate('seller.confirmProductTransactionHead')}
                </th>
              </tr>
            )}
            {transactionList.map((item) => (
              <tr key={item.name} className="summary-row">
                <td>{translate(`seller.transaction.${item.name}`)}</td>
                <td>
                  {getCurrencySymbol(currency)} {item.value.toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="buttons">
        <Button
          text={translate('seller.yesThatsRight')}
          action={confirmProduct}
          loading={isLoading}
        />
        <Button
          primary={false}
          text={translate('seller.noThatsIncorrect')}
          action={(): void => goToLink(language, Link.CONFIRM_PRODUCT_HELP)}
        />
      </div>
    </div>
  );
}

export default SellerConfirmProduct;
