import { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { apiPost } from '@/util/api';
import { useReduxDispatch } from '@/store/hooks';
import { setError } from '@/store/payment';
import Loader, { LoaderStyles } from '@/components/Loader';
import { LoginPostExchangeRequest } from '@/models/api/login/PostExchangeRequest';
import { LoginPostExchangeResponse } from '@/models/api/login/PostExchangeResponse';
import './GetLoginToken.scss';
import { ErrorCodes } from '@/models/ErrorCodes';

let hasFetched = false;

function GetLoginToken(): ReactElement {
  const [queryParams] = useSearchParams();
  const [loginToken, setLoginToken] = useState('');
  const dispatch = useReduxDispatch();

  const ott = queryParams.get('ott') || '';
  const redirectUrl = queryParams.get('redirectUrl') || '';

  const redirectToFlow = (): void => {
    const urlString = window.atob(redirectUrl);
    const url = new URL(urlString);

    if (url.origin !== window.location.origin) {
      dispatch(setError(ErrorCodes.INVALID_ORIGIN));
      return;
    }

    window.location.href = url.href;
  };

  useEffect(() => {
    if (loginToken) {
      sessionStorage.setItem('loginToken', loginToken);

      redirectToFlow();
    }
  }, [loginToken]);

  useEffect(() => {
    if (hasFetched) {
      return;
    }
    hasFetched = true;

    if (!ott) {
      dispatch(setError(ErrorCodes.MISSING_OTT));
      return;
    }

    if (!redirectUrl) {
      dispatch(setError(ErrorCodes.MISSING_REDIRECT));
      return;
    }

    const exchangeOttForLoginToken = async (): Promise<void> => {
      const response = await apiPost<
        LoginPostExchangeRequest,
        LoginPostExchangeResponse
      >('/auth/exchange', {
        type: 'one-time-token',
        oneTimeToken: ott,
      });

      if (response.isErr()) {
        dispatch(setError(ErrorCodes.OTT_EXCHANGE_REQUEST));
        return;
      }

      const { loginToken } = response.value;

      setLoginToken(loginToken);
    };

    exchangeOttForLoginToken().catch(() => {
      dispatch(setError(ErrorCodes.OTT_EXCHANGE_REQUEST));
    });
  }, [ott, redirectUrl]);

  return (
    <div className="get-login-token">
      <Loader color="#000" style={LoaderStyles.ROTATE_CIRCLE} />
    </div>
  );
}

export default GetLoginToken;
