import React, { ReactElement, useEffect, useState } from 'react';
import Stepper from '@/components/Stepper';
import IntroImage from '@/components/IntroImage';
import Button from '@/components/Button';
import { translate } from '@/util/strings';
import { setError, showLoader } from '@/store/payment';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { apiPut } from '@/util/api';
import { ErrorCodes } from '@/models/ErrorCodes';
import { models } from '@swiftcourt/pay-spec';
import {
  availablePaymentMethods,
  setPaymentMethodChoice,
  paymentMethodChoice,
} from '@/store/payment';
import { AbortPaymentMethodRequest } from '@/models/api/buyer/AbortPaymentMethodRequest';
import { AbortPaymentMethodResponse } from '@/models/api/buyer/AbortPaymentMethodResponse';
import { ChoosePaymentMethodRequest } from '@/models/api/buyer/ChoosePaymentMethodRequest';
import { ChoosePaymentMethodResponse } from '@/models/api/buyer/ChoosePaymentMethodResponse';
import RadioButtonsList from '@/components/RadioButtonsList';

function BuyerChoosePaymentMethod(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const paymentId: string = useReduxSelector(
    (state) => state.payment.paymentId
  );
  const selectedPaymentMethod = useReduxSelector(paymentMethodChoice);
  const paymentMethods: models.v1.PaymentMethod[] = useReduxSelector(
    availablePaymentMethods
  );
  const [radioButtonOptions, setRadioButtonOptions] = useState<
    { value: string; label: string; description: string }[]
  >([]);
  const dispatch = useReduxDispatch();

  useEffect(() => {
    const options = paymentMethods.map((pm) => {
      const value = pm;
      const label = translate(`buyer.paymentMethod.${pm}.label`);
      const description = translate(`buyer.paymentMethod.${pm}.description`);
      return { value, label, description };
    });
    setRadioButtonOptions(options);
  }, [paymentMethods]);

  const selectPaymentMethod = (
    paymentMethod: models.v1.PaymentMethod
  ): void => {
    dispatch(setPaymentMethodChoice(paymentMethod));
  };

  const submitPaymentMethodChoice = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<
      ChoosePaymentMethodRequest,
      ChoosePaymentMethodResponse
    >(`/payments/${paymentId}/payment-method/done`, {
      paymentMethod: selectedPaymentMethod,
    });

    if (apiRes.isErr()) {
      dispatch(setError(ErrorCodes.CHOOSE_PAYMENT_METHOD_REQUEST));
    }

    dispatch(showLoader());
  };

  const abortPaymentMethod = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<
      AbortPaymentMethodRequest,
      AbortPaymentMethodResponse
    >(`/payments/${paymentId}/payment-method/abort`, {});

    if (apiRes.isErr()) {
      dispatch(setError(ErrorCodes.ABORT_PAYMENT_METHOD_REQUEST));
    }

    dispatch(showLoader());
  };

  return (
    <div className="choose-payment-method">
      <Stepper steps={4} active={3} complete={2} />
      <IntroImage src="/img/phone-with-piggy-bank.svg" width={136} />
      <h1>{translate('buyer.choosePaymentMethodHead')}</h1>
      <RadioButtonsList
        title={translate('buyer.paymentMethodListHead')}
        options={radioButtonOptions}
        selectedOption={selectedPaymentMethod}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          selectPaymentMethod(event.target.value as models.v1.PaymentMethod);
        }}
      ></RadioButtonsList>
      <div className="buttons">
        <Button
          text={translate('buyer.submit')}
          action={submitPaymentMethodChoice}
          loading={isLoading}
          disabled={selectedPaymentMethod === models.v1.PaymentMethod.NOT_SET}
        />
        <Button
          text={translate('buyer.previousPage')}
          action={abortPaymentMethod}
          loading={isLoading}
          primary={false}
        />
      </div>
    </div>
  );
}

export default BuyerChoosePaymentMethod;
