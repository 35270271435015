import { ReactElement } from 'react';
import { translate } from '@/util/strings';
import './KlarnaPopupError.scss';

function KlarnaPopupError(): ReactElement {
  return (
    <div
      className="klarna-popup-error-box"
      dangerouslySetInnerHTML={{
        __html: translate('klarnaPopupError'),
      }}
    ></div>
  );
}

export default KlarnaPopupError;
