import { ReactElement } from 'react';
import classNames from 'classnames';
import './Loader.scss';

export enum LoaderStyles {
  HORIZONTAL_BUBBLES = 'horizontal-bubbles',
  ROTATE_CIRCLE = 'rotate-circle',
}

interface LoaderProps {
  color?: string;
  style?: LoaderStyles;
}

function Loader({
  color = '#fff',
  style = LoaderStyles.HORIZONTAL_BUBBLES,
}: LoaderProps): ReactElement {
  const className = classNames({
    loader: true,
    [`loader--${style}`]: true,
  });

  return (
    <div className={className} style={{ ['--loader-color' as string]: color }}>
      <span className="loader"></span>
    </div>
  );
}

export default Loader;
