import { ReactElement, useEffect, useState } from 'react';
import IntroImage from '@/components/IntroImage';
import './Loading.scss';
import { translate } from '@/util/strings';
import Button from '@/components/Button';

function Loading(): ReactElement {
  const [head, setHead] = useState('loadingHead1');
  const [body, setBody] = useState('loadingBody1');
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const navEntries: PerformanceNavigationTiming[] =
      window.performance.getEntriesByType(
        'navigation'
      ) as PerformanceNavigationTiming[];

    const isReload =
      (window.performance.navigation &&
        window.performance.navigation.type === 1) ||
      navEntries.map((nav) => nav.type).includes('reload');

    if (isReload) {
      setHead('loadingHead2');
      setBody('loadingBody3');
    } else {
      setTimeout(() => {
        setHead('loadingHead1');
        setBody('loadingBody2');
      }, 15000);

      setTimeout(() => {
        setHead('loadingHead2');
        setBody('loadingBody4');
        setShowButton(true);
      }, 30000);
    }
  }, []);

  return (
    <div className="loading">
      <IntroImage src="/img/hour-glass.svg" width={56} />
      <h1>{translate(head)}</h1>

      <div dangerouslySetInnerHTML={{ __html: translate(body) }} />

      {showButton && (
        <div className="buttons">
          <Button
            action={(): void => {
              window.location.reload();
            }}
            text={translate('reload')}
          />
        </div>
      )}
    </div>
  );
}

export default Loading;
