import { ReactElement, useEffect, useState } from 'react';
import './BankBranchSelector.scss';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { translate } from '@/util/strings';
import { setBankBranchChoice } from '@/store/payment';
import classNames from 'classnames';

const MAX_VISIBLE = 5;

interface BankBranchSubset {
  id: string;
  group: string;
  name: string;
}
interface BankBranchSelectorProps {
  banks: BankBranchSubset[];
}

function BankBranchSelector({ banks }: BankBranchSelectorProps): ReactElement {
  const [filteredBranches, setFilteredBranches] =
    useState<BankBranchSubset[]>(banks);

  const [searchQuery, setSearchQuery] = useState('');
  const bankChoice = useReduxSelector((state) => state.payment.bankChoice);

  const [bankBranches, setBankBranches] = useState<BankBranchSubset[]>(banks);

  const bankBranchChoice = useReduxSelector(
    (state) => state.payment.bankBranchChoice
  );
  const dispatch = useReduxDispatch();

  const selectBankBranch = (bankBranch: string): void => {
    dispatch(setBankBranchChoice(bankBranch));
  };

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    !!bankBranches.length &&
      setFilteredBranches(
        bankBranches.filter((branch) =>
          branch.name.toLowerCase().match(`${searchQuery.toLowerCase()}`)
        )
      );
  }, [searchQuery, bankChoice, bankBranches]);

  useEffect(() => {
    setBankBranches(
      banks
        .filter((branch) => branch.group === bankChoice)
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    );
  }, [bankChoice]);

  return (
    <div className="bank-branch-selector">
      <div className="branch-search">
        <div>{translate('searchForBank')}</div>
        <input type="search" onChange={updateSearch} />
      </div>
      {filteredBranches.length > 0 && (
        <ul
          className={classNames({
            overflow: filteredBranches.length > MAX_VISIBLE,
          })}
          style={{
            ['--num-branches' as string]: Math.min(
              filteredBranches.length,
              MAX_VISIBLE
            ),
          }}
        >
          {filteredBranches.map((branch) => (
            <li
              key={branch.id}
              className={classNames({
                selected: bankBranchChoice === branch.id,
              })}
              onClick={(): void => {
                selectBankBranch(branch.id);
              }}
            >
              {branch.name}
            </li>
          ))}
        </ul>
      )}
      {filteredBranches.length === 0 && (
        <div className="no-banks-found">{translate('noBanksFound')}</div>
      )}
    </div>
  );
}

export default BankBranchSelector;
