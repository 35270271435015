import { ReactElement } from 'react';
import TinkRedirect from '@/components/TinkRedirect';
import { translate } from '@/util/strings';

function BuyerTinkRedirect(): ReactElement {
  return (
    <>
      <TinkRedirect
        beforeRedirectText={translate('buyer.beforeTinkRedirect')}
        afterRedirectText={translate('buyer.afterTinkRedirect')}
      />
    </>
  );
}

export default BuyerTinkRedirect;
