// Common
import Header from '@/components/Header';
import Footer from '@/components/Footer';

import { ReactElement } from 'react';

// Font-awesome icons
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core';
import {
  faSortDown,
  faArrowLeft,
  faDownload,
  faClose,
} from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import PageRouter from '@/PageRouter';
import Error from '@/views/Error';
import { useReduxSelector } from '@/store/hooks';

FontAwesomeLibrary.add(fas, faSortDown, faArrowLeft, faDownload, faClose);

function App(): ReactElement {
  const error: string = useReduxSelector((state) => state.payment.error);
  const vertical = useReduxSelector((state) => state.payment.vertical);
  const language = useReduxSelector((state) => state.payment.language);
  const hasReceivedResponse = useReduxSelector(
    (state) => state.payment.hasReceivedResponse
  );

  return (
    <div className={`app-wrapper ${vertical}`}>
      {hasReceivedResponse && <Header key={vertical} />}
      <main>
        {error && <Error />}
        {!error && <PageRouter key={vertical} />}
      </main>
      {hasReceivedResponse && <Footer key={language} />}
    </div>
  );
}

export default App;
