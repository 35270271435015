import React, { ReactElement, useEffect, useState } from 'react';
import Icon from './Icon';
import './PopUp.scss';

interface PopUpProps {
  popUpIsOpen: boolean;
  children?: React.ReactNode;
  onClose: (close: boolean) => void;
}

function PopUp({
  popUpIsOpen = false,
  children,
  onClose,
}: PopUpProps): ReactElement {
  const [visible, setVisible] = useState(false);

  const closeHandler = (): void => {
    setVisible(false);
    onClose(false);
  };

  useEffect(() => {
    setVisible(popUpIsOpen);
  }, [popUpIsOpen]);

  return (
    <div className={`pop-up-${visible ? 'visible' : 'hidden'}`}>
      <div className="overlay" onClick={(): void => closeHandler()} />
      <div className="pop-up">
        <div className="pop-up-inner">
          <span className="close" onClick={closeHandler}>
            <Icon name="close" />
          </span>
          <div className="content">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default PopUp;
