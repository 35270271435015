import { ReactElement, useState } from 'react';
import Button from '@/components/Button';
import IntroImage from '@/components/IntroImage';
import Stepper from '@/components/Stepper';
import { translate } from '@/util/strings';
import './SellerChooseBank.scss';
import BankSelector from '@/components/BankSelector';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { apiPut } from '@/util/api';
import { setError, bankChoiceDone } from '@/store/payment';
import { OuterPutBankRequest } from '@/models/api/outer/PutBankRequest';
import { OuterPutBankResponse } from '@/models/api/outer/PutBankResponse';
import { ErrorCodes } from '@/models/ErrorCodes';

function SellerChooseBank(): ReactElement {
  const paymentId = useReduxSelector((state) => state.payment.paymentId);
  const bankBranchChoice = useReduxSelector(
    (state) => state.payment.bankBranchChoice
  );
  const choiceDone = useReduxSelector(bankChoiceDone);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useReduxDispatch();

  const submitBankChoice = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<OuterPutBankRequest, OuterPutBankResponse>(
      `payments/${paymentId}/bank`,
      { bankChoice: bankBranchChoice }
    );
    if (!apiRes.isOk()) {
      dispatch(setError(ErrorCodes.BANK_CHOICE_REQUEST));
    }
  };

  return (
    <div className="seller-choose-bank">
      <Stepper steps={4} active={2} complete={1} />
      <IntroImage src="/img/wallet.svg" width={99} />
      <h1>{translate('chooseBankHead')}</h1>
      <div
        dangerouslySetInnerHTML={{ __html: translate('seller.chooseBankBody') }}
      />

      <div className="bank-selector-wrapper">
        <BankSelector />
      </div>

      <div className="buttons">
        <Button
          text={translate('seller.continue')}
          action={submitBankChoice}
          loading={isLoading}
          disabled={!choiceDone}
        />
      </div>
    </div>
  );
}

export default SellerChooseBank;
