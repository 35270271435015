import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { models, ui as uiSpec } from '@swiftcourt/pay-spec';

export enum PaymentRole {
  UNSET = '',
  SELLER = 'seller',
  BUYER = 'buyer',
  ADMIN = 'admin',
}

export interface PaymentProduct {
  name: string;
  amount: number;
}

export interface BankBranch {
  id: string;
  group: string;
  name: string;
}

export interface PaymentState {
  paymentId: string;
  contractId: string;
  uiState: uiSpec.UiState;
  clientToken: string;
  tinkUrl: string;
  role: PaymentRole;
  error: string;
  showLoader: boolean;
  product: models.v2.paymentProduct.PaymentProduct;
  transactionList: models.v2.getPayment.TransactionListItem[];
  buyerName: string;
  sellerName: string;
  sellerIsDone: boolean;
  bankChoice: string;
  bankBranchChoice: string;
  bankBranches: BankBranch[];
  vertical: models.v2.Vertical;
  language: models.v2.Language;
  country: models.v1.Country;
  hasReceivedResponse: boolean;
  currency: models.v2.Currency;
  receipt: models.v2.receipt.UserReceipt;
  availablePaymentMethods: models.v1.PaymentMethod[];
  paymentMethodChoice: models.v1.PaymentMethod;
  ibanPayment: models.v1.IbanPayment;
  overfunded: boolean;
}

const initialState: PaymentState = {
  paymentId: '',
  contractId: '',
  uiState: uiSpec.UiState.SELLER_NOT_STARTED,
  clientToken: '',
  tinkUrl: '',
  role: PaymentRole.SELLER,
  error: '',
  showLoader: false,
  product: {
    data: [],
  },
  transactionList: [],
  buyerName: '',
  sellerName: '',
  sellerIsDone: false,
  bankChoice: '',
  bankBranchChoice: '',
  bankBranches: [],
  vertical: 'car',
  language: models.v2.Language.EN,
  country: models.v1.Country.NOT_SET,
  hasReceivedResponse: false,
  currency: models.v2.Currency.NOT_SET,
  receipt: {
    transaction: {
      ready: false,
    },
  },
  availablePaymentMethods: [],
  paymentMethodChoice: models.v1.PaymentMethod.NOT_SET,
  ibanPayment: {
    recipientName: '',
    accountNumber: '',
    accountType: models.v1.AccountType.NOT_SET,
    bic: '',
    paymentReference: '',
  },
  overfunded: false,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    reset: () => initialState,
    showLoader: (state) => {
      state.showLoader = true;
    },
    hideLoader: (state) => {
      state.showLoader = false;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setPaymentId: (state, action: PayloadAction<string>) => {
      state.paymentId = action.payload;
    },
    setContractId: (state, action: PayloadAction<string>) => {
      state.contractId = action.payload;
    },
    setClientToken: (state, action: PayloadAction<string>) => {
      state.clientToken = action.payload;
    },
    setTinkUrl: (state, action: PayloadAction<string>) => {
      state.tinkUrl = action.payload;
    },
    setRole: (state, action: PayloadAction<PaymentRole>) => {
      state.role = action.payload;
    },
    setUiState: (state, action: PayloadAction<uiSpec.UiState>) => {
      state.uiState = action.payload;
    },
    setProduct: (
      state,
      action: PayloadAction<models.v2.paymentProduct.PaymentProduct>
    ) => {
      state.product = action.payload;
    },
    setBuyerName: (state, action: PayloadAction<string>) => {
      state.buyerName = action.payload;
    },
    setSellerName: (state, action: PayloadAction<string>) => {
      state.sellerName = action.payload;
    },
    setSellerIsDone: (state, action: PayloadAction<boolean>) => {
      state.sellerIsDone = action.payload;
    },
    setBankChoice: (state, action: PayloadAction<string>) => {
      state.bankChoice = action.payload;
    },
    setPaymentMethodChoice: (
      state,
      action: PayloadAction<models.v1.PaymentMethod>
    ) => {
      state.paymentMethodChoice = action.payload;
    },
    setPayment: (
      state,
      action: PayloadAction<models.v2.getPayment.GetPayment>
    ) => {
      state.paymentId = action.payload.paymentId;
      state.uiState = action.payload.uiState;
      state.contractId = action.payload.contractId;
      state.sellerName = action.payload.seller.name;
      state.buyerName = action.payload.buyer.name;
      state.product = action.payload.product;
      state.transactionList = action.payload.transactionList;
      state.clientToken = action.payload.clientToken || '';
      state.tinkUrl = action.payload.tinkUrl || '';
      state.error = action.payload.error ? action.payload.error.errorId : '';
      state.vertical = action.payload.vertical;
      state.language = action.payload.language;
      state.country = action.payload.country;
      state.hasReceivedResponse = true;
      state.currency = action.payload.currency;
      state.receipt = action.payload.receipt;
      state.availablePaymentMethods =
        action.payload.availablePaymentMethods || [];
      state.ibanPayment = action.payload.ibanPayment || {
        recipientName: '',
        accountNumber: '',
        accountType: models.v1.AccountType.NOT_SET,
        bic: '',
        paymentReference: '',
      };
      state.overfunded = action.payload.overfunded || false;
    },
    setBankBranchChoice: (state, action: PayloadAction<string>) => {
      state.bankBranchChoice = action.payload;
    },
    setBankBranches: (state, action: PayloadAction<BankBranch[]>) => {
      state.bankBranches = action.payload;
    },
  },
});

export const {
  reset,
  setRole,
  setPaymentId,
  setContractId,
  setUiState,
  setClientToken,
  setTinkUrl,
  setError,
  setProduct,
  setPayment,
  setBuyerName,
  setSellerName,
  setBankChoice,
  setPaymentMethodChoice,
  setBankBranchChoice,
  showLoader,
  hideLoader,
  setSellerIsDone,
  setBankBranches,
} = paymentSlice.actions;

export const paymentId = (state: RootState): string => state.payment.paymentId;

export const contractId = (state: RootState): string =>
  state.payment.contractId;

export const clientToken = (state: RootState): string =>
  state.payment.clientToken;

export const tinkUrl = (state: RootState): string => state.payment.tinkUrl;

export const uiState = (state: RootState): uiSpec.UiState =>
  state.payment.uiState;

export const role = (state: RootState): PaymentRole => state.payment.role;

export const error = (state: RootState): string => state.payment.error;

export const product = (
  state: RootState
): models.v2.paymentProduct.PaymentProduct => state.payment.product;

export const transaction = (
  state: RootState
): models.v2.getPayment.TransactionListItem[] => state.payment.transactionList;

export const availablePaymentMethods = (
  state: RootState
): models.v1.PaymentMethod[] => state.payment.availablePaymentMethods;

export const paymentMethodChoice = (
  state: RootState
): models.v1.PaymentMethod => state.payment.paymentMethodChoice;

export const sellerName = (state: RootState): string =>
  state.payment.sellerName;

export const buyerName = (state: RootState): string => state.payment.buyerName;

export const bankChoice = (state: RootState): string =>
  state.payment.bankChoice;

export const bankBranchChoice = (state: RootState): string =>
  state.payment.bankBranchChoice;

export const bankChoiceDone = (state: RootState): boolean => {
  const bankHasBranches =
    state.payment.bankBranches.filter(
      (branch) => branch.group === state.payment.bankChoice
    ).length > 1;
  const branchIsSet = !!state.payment.bankBranchChoice;
  return state.payment.bankChoice !== '' && (!bankHasBranches || branchIsSet);
};

export const productName = (state: RootState): string =>
  [
    state.payment.product.data.find((dataItem) => dataItem.name === 'brand')
      ?.value,
    state.payment.product.data.find((dataItem) => dataItem.name === 'model')
      ?.value,
  ]
    .join(' ')
    .trim();

export const ibanPayment = (state: RootState): models.v1.IbanPayment =>
  state.payment.ibanPayment;

export default paymentSlice.reducer;
