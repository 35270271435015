import { ReactElement } from 'react';
import './TermsCheckbox.scss';

declare interface TermsCheckboxProps {
  content: string;
  value: boolean;
  onChange: () => void;
}

function TermsCheckbox({
  content,
  value,
  onChange,
}: TermsCheckboxProps): ReactElement {
  return (
    <div className="terms">
      <div>
        <input type="checkbox" id="terms" checked={value} onChange={onChange} />
      </div>
      <div>
        <label
          htmlFor="terms"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></label>
      </div>
    </div>
  );
}

export default TermsCheckbox;
