import { useReduxSelector } from '@/store/hooks';
import { ReactElement } from 'react';
import './Header.scss';

function Header(): ReactElement {
  const vertical = useReduxSelector((state) => state.payment.vertical);

  return (
    <header>
      <div className="logos">
        <img src={`/${vertical}/img/header/swiftcourt.svg`} />
      </div>
    </header>
  );
}

export default Header;
