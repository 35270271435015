import { ReactElement } from 'react';
import classNames from 'classnames';
import './StepperStep.scss';

interface StepperStepProps {
  active?: boolean;
  complete?: boolean;
}

function StepperStep({
  active = false,
  complete = false,
}: StepperStepProps): ReactElement {
  const className = classNames({
    step: true,
    'step--active': active,
    'step--complete': complete,
  });

  return <div className={className}></div>;
}

export default StepperStep;
