import { models } from '@swiftcourt/pay-spec';

export enum Link {
  CONTACT = 'contact',
  PRIVACY = 'privacy',
  TERMS = 'terms',
  CONFIRM_PRODUCT_HELP = 'confirmProductHelp',
  HANDOVER_HELP = 'handoverHelp',
}

declare interface LocalSupportLinks {
  [Link.CONTACT]: string;
  [Link.PRIVACY]: string;
  [Link.TERMS]: string;
  [Link.CONFIRM_PRODUCT_HELP]: string;
  [Link.HANDOVER_HELP]: string;
}

declare interface LanguageLinkMap {
  [models.v2.Language.DE]: LocalSupportLinks;
  [models.v2.Language.EN]: LocalSupportLinks;
  [models.v2.Language.SV]: LocalSupportLinks;
  [models.v2.Language.NO]: LocalSupportLinks;
  [models.v2.Language.FI]: LocalSupportLinks;
}

export const languageLinkMap: LanguageLinkMap = {
  [models.v2.Language.DE]: {
    [Link.CONTACT]: 'https://swiftcourt.com/de/privat/support',
    [Link.PRIVACY]:
      'https://swiftcourt.com/de/policies/payment-service-privacy-policy',
    [Link.TERMS]:
      'https://swiftcourt.com/de/policies/payment-service-terms-and-conditions',
    [Link.CONFIRM_PRODUCT_HELP]:
      'https://support.swiftcourt.com/de/articles/6973798-die-kaufsumme-ist-nicht-korrekt',
    [Link.HANDOVER_HELP]:
      'https://support.swiftcourt.com/de/articles/6973896-wie-kann-ich-eine-beschwerde-einreichen',
  },
  [models.v2.Language.EN]: {
    [Link.CONTACT]: 'https://swiftcourt.com/en/private/support',
    [Link.PRIVACY]:
      'https://swiftcourt.com/en/policies/payment-service-privacy-policy',
    [Link.TERMS]:
      'https://swiftcourt.com/en/policies/payment-service-terms-and-conditions',
    [Link.CONFIRM_PRODUCT_HELP]:
      'https://support.swiftcourt.com/en/articles/6973798-the-purchase-sum-is-incorrect',
    [Link.HANDOVER_HELP]:
      'https://support.swiftcourt.com/en/articles/6973896-how-to-start-a-complaint',
  },
  [models.v2.Language.SV]: {
    [Link.CONTACT]: 'https://swiftcourt.com/sv/privat/support',
    [Link.PRIVACY]:
      'https://swiftcourt.com/sv/villkor/payment-service-privacy-policy',
    [Link.TERMS]:
      'https://swiftcourt.com/sv/villkor/payment-service-terms-and-conditions',
    [Link.CONFIRM_PRODUCT_HELP]:
      'https://support.swiftcourt.com/sv/articles/6973798-kopesumman-stammer-inte',
    [Link.HANDOVER_HELP]:
      'https://support.swiftcourt.com/sv/articles/6973896-hur-du-startar-ett-klagomal',
  },
  [models.v2.Language.NO]: {
    [Link.CONTACT]: 'https://swiftcourt.com/no/privat/support',
    [Link.PRIVACY]:
      'https://swiftcourt.com/no/brukeravtale/payment-service-privacy-policy',
    [Link.TERMS]:
      'https://swiftcourt.com/no/brukeravtale/payment-service-terms-and-conditions',
    [Link.CONFIRM_PRODUCT_HELP]:
      'https://support.swiftcourt.com/nb/articles/6973798-kjopesummen-er-feil',
    [Link.HANDOVER_HELP]:
      'https://support.swiftcourt.com/nb/articles/6973896-hvordan-starte-en-klage',
  },
  [models.v2.Language.FI]: {
    [Link.CONTACT]: 'https://swiftcourt.com/en/private/support',
    [Link.PRIVACY]:
      'https://swiftcourt.com/fi/policies/payment-service-privacy-policy',
    [Link.TERMS]:
      'https://swiftcourt.com/fi/policies/payment-service-terms-and-conditions',
    [Link.CONFIRM_PRODUCT_HELP]:
      'https://support.swiftcourt.com/en/articles/6973798-the-purchase-sum-is-incorrect',
    [Link.HANDOVER_HELP]:
      'https://support.swiftcourt.com/en/articles/6973896-how-to-start-a-complaint',
  },
};

export function goToLink(language: models.v2.Language, link: Link): void {
  if (languageLinkMap[language] && languageLinkMap[language][link]) {
    window.location.href = languageLinkMap[language][link];
  }

  return;
}
