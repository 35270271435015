import { ReactElement } from 'react';
import { translate } from '@/util/strings';
import IntroImage from '@/components/IntroImage';
import Button from '@/components/Button';
import Stepper from '@/components/Stepper';
import TipsBox from '@/components/TipsBox';
import { redirectToContract } from '@/util/webApp';
import './SellerWaitingForPayIn.scss';
import { useReduxSelector } from '@/store/hooks';

function SellerWaitingForPayIn(): ReactElement {
  const contractId = useReduxSelector((state) => state.payment.contractId);
  const buyerName: string = useReduxSelector(
    (state) => state.payment.buyerName
  );

  return (
    <div className="waiting-for-pay-in">
      <Stepper steps={4} active={3} complete={2} />
      <IntroImage src="/img/hour-glass.svg" width={56} />

      <h1>{translate('moneyOnItsWayHead')}</h1>

      <div
        dangerouslySetInnerHTML={{
          __html: translate('seller.moneyOnItsWayBody').replaceAll(
            '[firstName]',
            buyerName
          ),
        }}
      />

      <TipsBox
        head={translate('tipsWhileYouWait2')}
        tips={[translate('seller.tipBox2')]}
      />

      <div className="buttons">
        <Button
          text={translate('returnToContract')}
          action={(): void => redirectToContract(contractId)}
          primary={false}
        />
      </div>
    </div>
  );
}

export default SellerWaitingForPayIn;
