const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

export function redirectToLogin(): void {
  sessionStorage.removeItem('loginToken');
  const returnUrl = new URL('/login', window.location.origin);
  returnUrl.searchParams.append(
    'redirectUrl',
    window.btoa(window.location.href)
  );

  const loginUrl = new URL('/auth/check', LOGIN_URL);
  loginUrl.searchParams.append(
    'redirectUrl',
    window.btoa(returnUrl.toString())
  );

  window.location.href = loginUrl.href;
}

export function redirectToContract(contractId: string): void {
  const contractUrl = new URL(
    `/#/app/contracts/${contractId}/ov?lang=en`,
    LOGIN_URL
  );
  window.location.href = contractUrl.href;
}
