import { ReactElement, useState } from 'react';
import Stepper from '@/components/Stepper';
import IntroImage from '@/components/IntroImage';
import Button from '@/components/Button';
import { translate } from '@/util/strings';
import './BuyerConfirmPurchase.scss';
import { setError, showLoader } from '@/store/payment';
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import { apiPut } from '@/util/api';
import { BuyerConfirmPurchaseRequest } from '@/models/api/buyer/ConfirmPurchaseRequest';
import { BuyerConfirmPurchaseResponse } from '@/models/api/buyer/ConfirmPurchaseResponse';
import { ErrorCodes } from '@/models/ErrorCodes';
import { getCurrencySymbol } from '@/util/currency';

function BuyerConfirmPurchase(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const paymentId: string = useReduxSelector(
    (state) => state.payment.paymentId
  );
  const dispatch = useReduxDispatch();

  const product = useReduxSelector((state) => state.payment.product);
  const sellerName: string = useReduxSelector(
    (state) => state.payment.sellerName
  );
  const transactionList = useReduxSelector(
    (state) => state.payment.transactionList
  );
  const currency = useReduxSelector((state) => state.payment.currency);

  const confirmPurchase = async (): Promise<void> => {
    setIsLoading(true);
    const apiRes = await apiPut<
      BuyerConfirmPurchaseRequest,
      BuyerConfirmPurchaseResponse
    >(`/payments/${paymentId}/purchase-confirmation`, {});

    if (apiRes.isErr()) {
      dispatch(setError(ErrorCodes.CONFIRM_PRODUCT_REQUEST));
    }

    dispatch(showLoader());
  };

  return (
    <div className="confirm-purchase">
      <Stepper steps={4} active={3} complete={2} />
      <IntroImage src="/img/phone-with-piggy-bank.svg" width={136} />
      <h1>{translate('buyer.sendYourPaymentHead')}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('buyer.sendYourPaymentBody').replaceAll(
            '[firstName]',
            sellerName
          ),
        }}
      />

      <div className="product-purchase-summary">
        <table>
          <thead>
            <tr>
              <th colSpan={2}>{translate('buyer.productTableHead')}</th>
            </tr>
          </thead>
          <tbody>
            {product.data.map((item) => (
              <tr key={item.name}>
                <td>{translate(`product.data.${item.name}`)}</td>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th colSpan={2}>{translate('buyer.toBePaidTableHead')}</th>
            </tr>
          </thead>
          <tbody>
            {transactionList.map((item) => (
              <tr key={item.name} className="summary-row">
                <td>{translate(`buyer.transaction.${item.name}`)}</td>
                <td>
                  {getCurrencySymbol(currency)} {item.value.toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="buttons">
        <Button
          text={translate('buyer.startPayment')}
          action={confirmPurchase}
          loading={isLoading}
        />
      </div>
    </div>
  );
}

export default BuyerConfirmPurchase;
