import { ReactElement } from 'react';

function NotFound(): ReactElement {
  return (
    <>
      <h1>404</h1>
    </>
  );
}

export default NotFound;
