import React, { ReactElement } from 'react';
import './RadioButtonsList.scss';

interface RadioButtonsListProps {
  title: string;
  options: { value: string; label: string; description: string }[];
  selectedOption: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const RadioButtonsList = ({
  title,
  options,
  selectedOption,
  onChange,
}: RadioButtonsListProps): ReactElement => {
  return (
    <div className="radio-buttons-list">
      <h3 className="title">{title}</h3>
      <ul className="radio-buttons-list">
        {options.map((option, index) => (
          <li key={index}>
            <label>
              <div className="radio-option">
                <input
                  type="radio"
                  value={option.value}
                  checked={selectedOption === option.value}
                  onChange={onChange}
                  className="radio-input"
                />
                <span
                  className="radio-label"
                  dangerouslySetInnerHTML={{ __html: option.label }}
                ></span>
              </div>
              <div
                className="radio-description"
                dangerouslySetInnerHTML={{ __html: option.description }}
              ></div>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RadioButtonsList;
